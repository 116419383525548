import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ConfigDB from "../../config";
import DatePicker from "react-multi-date-picker";
import Select from "react-select";
import { getVehicleList } from "../../services/VehicleService";
import { getDriverList } from "../../services/TripService";
import { DateRangePicker } from "react-date-range";
import { getFuelManagementReportList } from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";

const showReports = [
  {
    value: 1,
    label: "Detailed",
  },
  { value: 2, label: "Summarized" },
];

const FuelManagement = ({ setActiveTab }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;

  const [open, setOpen] = useState(false);
  const [FuelManagementData, setFuelManagementData] = useState({
    show_report: "",
    vehicle_id: "",
    include_chronometer: "",
    include_engine_hr: "",
    one_row_per_Day: "",
    subject: "",
    email: "",
  });

  const [modifyModal, setModifyModal] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  const [DriverList, setDriverList] = useState([]);

  const [dateRange, setDateRange] = useState("");

  const handleChange = (range) => {
    setDateRange(range);
  };

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const handleSendEmailOpen = () => {
    setModifyModal(true);
  };

  const handleSendEmailClose = () => {
    setModifyModal(false);
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setFuelManagementData({
      ...FuelManagement,
      include_chronometer: newValue,
    });
  };

  const handleCheckboxChangeEngine = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setFuelManagementData({
      ...FuelManagement,
      include_engine_hr: newValue,
    });
  };
  const handleCheckboxChangeOneRow = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setFuelManagementData({
      ...FuelManagement,
      one_row_per_Day: newValue,
    });
  };

  useEffect(
    function () {
      let data = { ...params };
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getDriverList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setDriverList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  const date = new Date(dateRange);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const handleAddFuelManagementForm = (e) => {
    e.preventDefault();

    if (!dateRange) {
      toast.error("Please select date.");
      return;
    }
    if (!FuelManagementData.vehicle_id) {
      toast.error("Please select one vehicle.");
      return;
    }
    let data = {
      vehicle_id: FuelManagementData?.vehicle_id,
      startDate: formattedDate,
      include_chronometer: FuelManagementData?.include_chronometer,
      include_engine_hr: FuelManagementData?.include_engine_hr,
      one_row_per_Day: FuelManagementData?.one_row_per_Day,
    };

    modifyFuel(getFuelManagementReportList, data);
  };
  const modifyFuel = (actionFunction, data) => {
    actionFunction(data)
      .then((response) => {
        const dataToSend = {
          vehicle_id: FuelManagementData?.vehicle_id,
          startDate: formattedDate,
          include_chronometer: FuelManagementData?.include_chronometer,
          include_engine_hr: FuelManagementData?.include_engine_hr,
          one_row_per_Day: FuelManagementData?.one_row_per_Day,
        };

        if (
          !response?.data ||
          response?.data?.data === null ||
          response?.data.data?.length <= 0
        ) {
          toast.error("No data found.");
          return;
        }
        localStorage.setItem(
          "FuelMangementReportData",
          JSON.stringify(dataToSend)
        );
        window.open("/fuel-reports", "_blank");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleAddFuelManagementForm}>
              <h6>FUEL MANAGEMENT</h6>
              <h6>
                This report shows, for a vehicle or selection of vehicles, the
                evolution of the major fuel indicators and operations detected
                in a period of time. This includes fuel level at the beginning
                and end of the period, refuelling and drainage detected, total
                consumption estimated and average consumption.
              </h6>
              <div className="d-flex justify-content-start">
                <div className="me-1">
                  <Button
                    color="success btn-info"
                    type={`submit`}
                    className={`btn-square`}
                  >{`Generate`}</Button>
                </div>
                {/* <div>
                  <Button
                    color="success btn-info"
                    type={`button`}
                    onClick={handleSendEmailOpen}
                    className={`btn-square`}
                  >{`Send Report By EMail`}</Button>
                </div> */}
              </div>
            </Form>
          </div>
          <div className="card card-primary general-ac">
            <Form method={`post`}>
              <h6>Configuration</h6>

              <div className="row">
                <div className="col-md-4">
                  <FormGroup>
                    <Label className="col-form-label">{"Period"}</Label>

                    <DatePicker
                      selected={dateRange}
                      onChange={handleChange}
                      dateFormat="yyyy-MM-dd"
                      calendarPosition="bottom-center"
                      placeholder="Select Date"
                      style={{
                        border: "1px solid #CCCCCC",
                        borderRadius: "5px",
                        height: "40px",
                        margin: "1px 0",
                        padding: "2px 5px",
                        paddingRight: "30px",
                        width: "100%",
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="col-md-4">
                  <FormGroup>
                    <Label className="col-form-label">{"Show Reports"}</Label>

                    <Select
                      options={showReports}
                      value={showReports.find(
                        (option) =>
                          option.value === FuelManagementData.show_report
                      )}
                      onChange={(selectedOption) =>
                        setFuelManagementData((prevData) => ({
                          ...prevData,
                          show_report: selectedOption.value,
                        }))
                      }
                    />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <Label className="col-form-label">{"Vehicle"}</Label>
                  <div className="input-div">
                    <Select
                      options={vehicleList}
                      value={vehicleList?.find(
                        (option) =>
                          option.value ===
                          JSON.stringify(FuelManagementData.vehicle_id)
                      )}
                      onChange={(selectedOption) =>
                        setFuelManagementData((prevData) => ({
                          ...prevData,
                          vehicle_id: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Vehicle"}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <Label className="col-form-label">Include Chronometer</Label>
                  <div className="input-div">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="tachographTypeCheckbox"
                        checked={FuelManagementData.include_chronometer}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="tachographTypeCheckbox"
                      >
                        Include Chronometer{" "}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <Label className="col-form-label">Include Engine Hr</Label>
                  <div className="input-div">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="include_engine_hr"
                        checked={FuelManagementData.include_engine_hr}
                        onChange={handleCheckboxChangeEngine}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="include_engine_hr"
                      >
                        Include Engine Hr{" "}
                      </label>
                    </div>
                  </div>
                </div>

                {FuelManagementData?.show_report === 1 && (
                  <div className="col-md-6">
                    <Label className="col-form-label">One row Per Day </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="one_row_per_Day"
                          checked={FuelManagementData.one_row_per_Day}
                          onChange={handleCheckboxChangeOneRow}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="one_row_per_Day"
                        >
                          Include Engine Hr{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal isOpen={modifyModal}>
        <ModalHeader>Send Report By Email </ModalHeader>
        <Form method={`post`}>
          <ModalBody>
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <Label className="col-form-label">{"Subject"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      name={`subject`}
                      onChange={(e) => {
                        setFuelManagementData({
                          ...FuelManagementData,
                          subject: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={FuelManagementData.subject}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Email"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      name={`email`}
                      onChange={(e) => {
                        setFuelManagementData({
                          ...FuelManagementData,
                          email: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={FuelManagementData.email}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={handleSendEmailClose}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success btn-info"
              type={`submit`}
              className={`btn-square`}
            >{`Submit`}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default FuelManagement;
