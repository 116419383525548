import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ConfigDB from "../../config";
import DatePicker from "react-multi-date-picker";
import Select from "react-select";
import { getVehicleList } from "../../services/VehicleService";
import {
  actionFuelCostReports,
  getRouteReportList,
  getVehicleDetailsReportList,
} from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";

const VehicleDetailsReport = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;

  const [FuelManagementData, setFuelManagementData] = useState({
    vehicle_id: "",
    subject: "",
    email: "",
    post_execution_detection: "0",
  });

  const [modifyModal, setModifyModal] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  const [dateRange, setDateRange] = useState("");

  const handleChange = (range) => {
    setDateRange(range);
  };

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setFuelManagementData({
      ...FuelManagementData,
      post_execution_detection: newValue,
    });
  };

  const handleSendEmailOpen = () => {
    setModifyModal(true);
  };

  const handleSendEmailClose = () => {
    setModifyModal(false);
  };

  useEffect(
    function () {
      let data = { ...params };
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => { });
    },
    [params]
  );
  const date = new Date(dateRange);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const handleAddFuelCostForm = (e) => {
    e.preventDefault();

    if (!FuelManagementData.vehicle_id) {
      toast.error("Please select  vehicle.");
      return; // Stop further execution if no vehicle is selected
    }

    if (!formattedDate) {
      toast.error("Please select  date.");
      return; // Stop further execution if no vehicle is selected
    }

    let data = {
      vehicle_id: FuelManagementData?.vehicle_id,
      startDate: formattedDate,
    };

    modifyFuel(getVehicleDetailsReportList, data);
  };
  const modifyFuel = (actionFunction, data) => {
    actionFunction(data)
      .then((response) => {
        const dataToSend = {
          vehicle_id: [FuelManagementData?.vehicle_id],
          startDate: formattedDate,
        };

        localStorage.setItem(
          "vehicleDetailsReportData",
          JSON.stringify(dataToSend)
        );
        if (!response?.data || response?.data?.data === null || response?.data.data?.length <= 0) {
          toast.error("No data found.");
          return;
        }
        window.open("/vehicle-details-reports", "_blank");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleAddFuelCostForm}>
              <h6>VEHICLE DETAILS</h6>
              <h6>
                This is one of Frotcom’s most complete reports. It shows, for
                each selected vehicle/driver, almost all relevant information:
                executed trips, triggered alarms, trip times and stop times,
                visited places, etc. Besides the information shown in table
                format, this report also includes a map showing the vehicle
                route and a graph with the evolution in velocity and optional
                sensors.
              </h6>
              <div className="d-flex justify-content-start">
                <div className="me-1">
                  <Button
                    color="success btn-info"
                    type={`submit`}
                    className={`btn-square`}
                  >{`Generate`}</Button>
                </div>
                {/* <div>
                  <Button
                    color="success btn-info"
                    type={`button`}
                    onClick={handleSendEmailOpen}
                    className={`btn-square`}
                  >{`Send Report By EMail`}</Button>
                </div> */}
              </div>
            </Form>
          </div>
          <div className="card card-primary general-ac">
            <Form method={`post`}>
              <h6>Configuration</h6>

              <div className="row">
                <div className="col-md-4">
                  <FormGroup>
                    <Label className="col-form-label">{"Period"}</Label>

                    <DatePicker
                      selected={dateRange}
                      onChange={handleChange}
                      dateFormat="yyyy-MM-dd"
                      calendarPosition="bottom-center"
                      placeholder="Select Date"
                      style={{
                        border: "1px solid #CCCCCC",
                        borderRadius: "5px",
                        height: "40px",
                        margin: "1px 0",
                        padding: "2px 5px",
                        paddingRight: "30px",
                        width: "100%",
                      }}
                    />
                    <i class="bi bi-calendar-event" style={{ position: "absolute", left: "30%", top: "93px" }}></i>

                  </FormGroup>
                </div>

                <div className="col-md-4">
                  <Label className="col-form-label">{"Vehicle"}</Label>
                  <div className="input-div">
                    <Select
                      options={vehicleList}
                      value={vehicleList?.find(
                        (option) =>
                          option.value ===
                          JSON.stringify(FuelManagementData.vehicle_id)
                      )}
                      onChange={(selectedOption) =>
                        setFuelManagementData((prevData) => ({
                          ...prevData,
                          vehicle_id: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Vehicle"}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal isOpen={modifyModal}>
        <ModalHeader>Send Report By Email </ModalHeader>
        <Form method={`post`}>
          <ModalBody>
            <Form>
              <div className="row">
                <div className="col-md-3">
                  <Label className="col-form-label">{"Subject"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      name={`subject`}
                      onChange={(e) => {
                        setFuelManagementData({
                          ...FuelManagementData,
                          subject: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={FuelManagementData.subject}
                    // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="col-md-3">
                  <Label className="col-form-label">{"Email"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      name={`email`}
                      onChange={(e) => {
                        setFuelManagementData({
                          ...FuelManagementData,
                          email: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={FuelManagementData.email}
                    // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={handleSendEmailClose}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success btn-info"
              type={`submit`}
              className={`btn-square`}
            >{`Submit`}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default VehicleDetailsReport;
