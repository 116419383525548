import axiosInstance from "./AxiosInstance";

export function getTachnoDriverList(data) {
  return axiosInstance.post(`/tachograph-driver`, data);
}

export function getTachnoVehicleList(data) {
  return axiosInstance.post(`/tachograph-vehicle`, data);
}

export function getTachnoGraphCountryList(data) {
  return axiosInstance.post(`/tachograph-country`, data);
}

export function getTachnoGraphTimeZoneList(data) {
  return axiosInstance.post(`/tachograph-time-zone`, data);
}

export function getTachnoGraphDriverPdf(data) {
  return axiosInstance.post(`/tachograpg-pdf-driver`, data);
}

export function getTachnoGraphDriverAnalysis(data) {
  return axiosInstance.post(`/tachograpg-analysis-driver`, data);
}

// export function getTachnogerateList(data) {
//   return axiosInstance.post(`/tachograph-vehicle`, data);
// }
