import React, { useState } from "react";
import Tachgnograph from "./Tachgnograph";
import TachnographVehicle from "./TachnographVehicle";
import TachnographReport from "./TachnographReport";
import TachnoGraphAnalysis from "./TachnoGraphAnalysis";

const AddTachnograph = () => {
  const [activeTab, setActiveTab] = useState("tachnograph");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="inner-header py-3 d-none">
        <div className="left-block">
          <h5>Management</h5>
        </div>
      </div>

      <div className="leaflet-control-management managementui-tab leaflet-control-var">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "tachnograph" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("tachnograph");
                }}
              >
                <span data-href="#tab-1">Driver</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "vehicle" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("vehicle");
                }}
              >
                <span data-href="#tab-1">Vehicle</span>
              </li>

              <li
                className={`nav-link ${activeTab === "report" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("report");
                }}
              >
                <span data-href="#tab-1">Report</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "analysis" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("analysis");
                }}
              >
                <span data-href="#tab-1">Activity analysis</span>
              </li>
            </ul>
          </nav>

          <div className="dashboard-block tab-content tachnoDashboard" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "tachnograph" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <Tachgnograph setActiveTab={setActiveTab} />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "vehicle" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <TachnographVehicle setActiveTab={setActiveTab} />
            </div>


            <div
              className={`tab-pane fade ${
                activeTab === "report" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <TachnographReport setActiveTab={setActiveTab} />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "analysis" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <TachnoGraphAnalysis setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTachnograph;
