import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";
import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import {
  actionAddCompanyProvide,
  actionDelCompanyProvide,
  getCompanyProvideList,
  getCompanyTokenRestore,
} from "../../services/CompanyProviderService";
import { CopyToClipboard } from "react-copy-to-clipboard";

const tableLengthList = configDb.data.dataTableLength;

const CompanyProvider = (props) => {
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [companyProviderList, setCompanyProviderList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [modifyDetailsModal, setModifyDetailsModal] = useState(false);
  const [providerToken, setProviderToken] = useState({});
  const [delModal, setDelModal] = useState(false);
  const [companyProviderData, setcompanyProviderData] = useState({
    uuid: "",
    provider_name: "",
    provider_email: "",
    provider_contact: "",
  });

  let errorsObj = {
    provider_name: "",
    provider_email: "",
    provider_contact: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [delSubscription, setDelSubscription] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  // Toggle visibility
  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const oncompanyProvider = () => {
    setcompanyProviderData({
      uuid: "",
      provider_name: "",
      provider_email: "",
      provider_contact: "",
      provider_api_token: "",
    });
    setModifyModal(true);
  };

  const handlecompanyProviderClose = () => {
    setModifyModal(false);
  };

  const onDetailsShowProvider = (item) => {
    setModifyDetailsModal(true);
    setProviderToken(item);
  };

  const onDetailsModalClose = (e) => {
    setModifyDetailsModal(false);
  };

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getCompanyProvideList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setCompanyProviderList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );
  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const handleProviderForm = (e) => {
    e.preventDefault();
    let dataParams = { ...params };

    let error = false;
    let errorObj = { ...errorsObj };
    if (!companyProviderData.provider_name) {
      errorObj.provider_name = `Provider Name required.`;
      error = true;
    }

    if (!companyProviderData.provider_email) {
      errorObj.provider_email = `Provider Email required.`;
      error = true;
    }
    const phoneRegex = /^\d{9}$/;

    if (!companyProviderData.provider_contact) {
      errorObj.provider_contact = `Provider Contact required.`;
      error = true;
    } else if (!phoneRegex.test(companyProviderData.provider_contact)) {
      errorObj.provider_contact = `Provider Contact must be exactly 9 digits.`;
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      provider_name: companyProviderData?.provider_name,
      provider_email: companyProviderData?.provider_email,
      provider_contact: companyProviderData?.provider_contact,
    };
    actionAddCompanyProvide(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const onDetailsShowProviderRestore = (item) => {
    let dataParams = { ...params };

    if (item?.uuid) {
      let data = {
        uuid: item.uuid,
      };
      setIsLoading(true);

      getCompanyTokenRestore(data)
        .then((response) => {
          toast.success(response.data.message);
          dataParams.page = 1;
          dataParams.is_reload = !dataParams.is_reload;
          setParams(dataParams);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const onDeleteSubscription = (e, item) => {
    e.preventDefault();
    setDelSubscription(item.uuid);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelSubscription("");
    setDelModal(false);
  };

  const onDestroyRole = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delSubscription };
    actionDelCompanyProvide(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelSubscription("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const urlToCopy = `${ConfigDB.data.api_url}order-trip`;
  const handleCopy = () => {
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyParameter = () => {
    const commandText = document.querySelector(".curl-command code").innerText;

    navigator.clipboard
      .writeText(commandText)
      .then(() => {
        const tooltip = document.querySelector(".tooltips");
        tooltip.textContent = "Copied!";
        setTimeout(() => (tooltip.textContent = "Copy cURL command"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy command: ", err);
      });
  };

  const [copied, setCopied] = useState(false);

  const handleCopyDoucument = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Api Tokens List</h5>
        </div>
        <div className="right-block w-auto">
          <Button
            type={`button`}
            onClick={oncompanyProvider}
            className={`add-btn btn  float-right`}
          >
            {`Add Tokens List`}
            <i className="plus-circle">
              <img src={plusIcon} alt="" className="plus-icons" />
            </i>
          </Button>
        </div>
      </div>

      <div className="container-event">
        <div className="page-header-row">
          <div className="row">
            <div className="col-xl-12">
              <div className="row d-flex justify-content-between">
                <div className="col-xl-2">
                  <div className="selected-block selected-mobile">
                    <p>Show</p>
                    <select
                      className="form-select"
                      aria-label="Events Only"
                      defaultValue={params.limit}
                      onChange={(e) => handleParams(e, "length")}
                    >
                      {tableLengthList &&
                        tableLengthList.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="search-bar">
                    <i className="bi bi-search"></i>
                    <input
                      type="text"
                      className="form-control"
                      value={params.search}
                      onChange={(e) => handleParams(e, "search")}
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="page-table-row col-md-9">
            <table
              className={`table table-striped ${isLoading ? "loading" : ""}`}
              width="100%"
            >
              <thead>
                <tr>
                  <th scope={`col`}>{`Provider Name`}</th>
                  <th scope={`col`}>{`Provider Email`}</th>
                  <th scope={`col`}>{`Provider Contact`}</th>
                  <th scope={`col`}>{`Provider Api Token`}</th>

                  {allPermissionsList.findIndex(
                    (e) => e.name == "view_details_trip"
                  ) > -1 && <th scope={`col`}>{`Action`}</th>}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={6} className="text-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {companyProviderList &&
                      companyProviderList?.data?.map((item, i) => (
                        <tr>
                          <td style={{ cursor: "pointer" }}>
                            {item?.provider_name}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item?.provider_email}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item?.provider_contact}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={handleClick}
                          >
                            {isVisible
                              ? item?.provider_api_token
                              : "**********************"}
                          </td>

                          <td>
                            {/* <NavLink
                              role={`button`}
                              onClick={() => onDetailsShowProvider(item)}
                              className={`btn btn-success add-btn-circle me-2`}
                            >
                              <i
                                class="bi bi-eye icon-copy"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <span className="tooltips">View token</span>
                              </i>
                            </NavLink> */}

                            <NavLink
                              role={`button`}
                              onClick={() => onDetailsShowProviderRestore(item)}
                              className={`btn btn-success add-btn-circle me-2`}
                            >
                              <i
                                class="bi bi-arrow-repeat icon-copy"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <span className="tooltips">Rotate token</span>
                              </i>
                            </NavLink>

                            <CopyToClipboard
                              text="https://inroute.neutroveg.com/api-document"
                              onCopy={handleCopyDoucument}
                            >
                              <i
                                className="bi bi-copy icon-copy copy-icon-2"
                                style={{
                                  cursor: "pointer",
                                  marginRight: "10px",
                                }}
                              >
                                <span className="tooltips">
                                  {" "}
                                  View Api document
                                </span>
                              </i>
                            </CopyToClipboard>

                            <button
                              type={`button`}
                              onClick={(e) => onDeleteSubscription(e, item)}
                              className={`btn btn-circle btn-danger add-btn-circle me-2`}
                            >
                              <i className="bi bi-trash-fill"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </>
                )}

                {companyProviderList &&
                  companyProviderList?.data?.length === 0 && (
                    <tr>
                      <td
                        colSpan={
                          allPermissionsList.findIndex(
                            (e) => e.name == "modify_trip"
                          ) > -1 ||
                          allPermissionsList.findIndex(
                            (e) => e.name == "delete_trip"
                          ) > -1
                            ? 6
                            : 7
                        }
                        className={`text-center`}
                      >{`Record Not Found`}</td>
                    </tr>
                  )}
              </tbody>
            </table>
            <div className="api-reference">
              {/* HTTP Status Code Summary Section */}
              <div className="api-reference-box">
                <div className="section-title">HTTP Status Code Summary</div>
                <div className="table-container">
                  <table
                    className="api-reference-table"
                    style={{
                      border: "transparent",
                      boxShadow: "none",
                      borderRadius: "0",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Status Code</th>
                        <th>Short Description</th>
                        <th>Detailed Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>200</td>
                        <td>OK</td>
                        <td>Everything worked as expected.</td>
                      </tr>
                      <tr>
                        <td>400</td>
                        <td>Bad Request</td>
                        <td>
                          The request was unacceptable, often due to missing a
                          required parameter.
                        </td>
                      </tr>
                      <tr>
                        <td>401</td>
                        <td>Unauthorized</td>
                        <td>No valid API key provided.</td>
                      </tr>
                      <tr>
                        <td>402</td>
                        <td>Request Failed</td>
                        <td>
                          The parameters were valid but the request failed.
                        </td>
                      </tr>
                      <tr>
                        <td>403</td>
                        <td>Forbidden</td>
                        <td>
                          The API key doesn’t have permissions to perform the
                          request.
                        </td>
                      </tr>
                      <tr>
                        <td>404</td>
                        <td>Not Found</td>
                        <td>The requested resource doesn’t exist.</td>
                      </tr>
                      <tr>
                        <td>409</td>
                        <td>Conflict</td>
                        <td>
                          The request conflicts with another request (perhaps
                          due to using the same idempotent key).
                        </td>
                      </tr>
                      <tr>
                        <td>429</td>
                        <td>Too Many Requests</td>
                        <td>
                          Too many requests hit the API too quickly. We
                          recommend an exponential backoff of your requests.
                        </td>
                      </tr>
                      <tr>
                        <td>500, 502, 503, 504</td>
                        <td>Server Errors</td>
                        <td>
                          Something went wrong on Stripe’s end. (These are
                          rare.)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            {/* <div className="aside-section">
              <h2 className="heading">Not a developer?</h2>
              <p>
                Create Trip{" "}
                <a href="/payments/no-code" className="link">
                  <span>No-code options</span>
                </a>{" "}
                or apps from{" "}
                <a href="https://stripe.partners/" className="link">
                  <span>our partners</span>
                </a>{" "}
                to get started with Stripe and to do more with your Stripe
                account—no code required.
              </p>
            </div> */}

            <div className="base-url-section">
              <div className="base-url-header">
                <div className="base-url-title">Base URL</div>
                <button
                  className="copy-button"
                  onClick={handleCopy}
                  aria-label="Copy URL"
                >
                  <i class="bi bi-copy icon-copy">
                    <span className="tooltips">copied</span>
                  </i>
                </button>
              </div>
              <pre className="base-url">
                <code>{urlToCopy}</code>
              </pre>
            </div>

            <div className="authenticated-request">
              <div className="header">
                <div className="title">Create Order Trip Parameter</div>

                <button
                  className="copy-button"
                  aria-label="Copy cURL command"
                  onClick={handleCopyParameter}
                >
                  <i className="bi bi-copy icon-copy">
                    <span className="tooltips">Copy cURL command</span>
                  </i>
                </button>
              </div>
              <pre className="curl-command">
                <code>
                  <span className="curl-command-text">sourcePlace: ""</span>
                  <br />
                  <span className="curl-command-text">sourceLatitude: ""</span>
                  <br />
                  <span className="curl-command-text">sourceLongitude: ""</span>
                  <br />
                  <span className="curl-command-text">
                    destinationPlace: ""
                  </span>
                  <br />
                  <span className="curl-command-text">providerName: ""</span>
                  <br />
                  <span className="curl-command-text">
                    destinationLatitude: ""
                  </span>
                  <br />
                  <span className="curl-command-text">
                    destinationLongitude: ""
                  </span>
                  <br />
                  <span className="curl-command-text">totalDistance: ""</span>
                  <br />
                  <span className="curl-command-text">totalDuration: ""</span>
                  <br />
                  <span className="curl-command-text">price: ""</span>
                  <br />
                  <span className="comment">
                    # All are order create parameter.
                  </span>
                </code>
              </pre>
            </div>
            {/* Error Types Section */}
            <div className="api-reference-box">
              <div className="section-title">Error Types</div>
              <div className="table-container">
                <table className="api-reference-table">
                  <thead>
                    <tr>
                      <th>Error Type</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <code>api_error</code>
                      </td>
                      <td>
                        API errors cover any other type of problem (e.g., a
                        temporary problem with Stripe’s servers), and are
                        extremely uncommon.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>card_error</code>
                      </td>
                      <td>
                        Card errors are the most common type of error you should
                        expect to handle. They result when the user enters a
                        card that can’t be charged for some reason.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>idempotency_error</code>
                      </td>
                      <td>
                        Idempotency errors occur when an{" "}
                        <code>Idempotency-Key</code> is re-used on a request
                        that does not match the first request’s API endpoint and
                        parameters.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>invalid_request_error</code>
                      </td>
                      <td>
                        Invalid request errors arise when your request has
                        invalid parameters.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Add Company Provider</ModalHeader>
        <Form method={`post`} onSubmit={handleProviderForm}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Provider Name"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={companyProviderData.provider_name}
                      onChange={(e) => {
                        setcompanyProviderData({
                          ...companyProviderData,
                          provider_name: e.target.value,
                        });
                      }}
                      min={0}
                      name={`provider_name`}
                      maxLength={50}
                      placeholder="Provider Name"
                    />
                    {errors.provider_name && (
                      <span className="input-error">
                        {errors.provider_name}
                      </span>
                    )}
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label className="col-form-label">
                    {"Provider Contact"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={companyProviderData.provider_contact}
                      onChange={(e) => {
                        setcompanyProviderData({
                          ...companyProviderData,
                          provider_contact: e.target.value,
                        });
                      }}
                      min={0}
                      name={`provider_contact`}
                      maxLength={50}
                      placeholder="Provider Contact"
                    />
                    {errors.provider_contact && (
                      <span className="input-error">
                        {errors.provider_contact}
                      </span>
                    )}
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label className="col-form-label">
                    {"Provider Email"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={companyProviderData.provider_email}
                      onChange={(e) => {
                        setcompanyProviderData({
                          ...companyProviderData,
                          provider_email: e.target.value,
                        });
                      }}
                      min={0}
                      name={`provider_email`}
                      maxLength={50}
                      placeholder="Provider Email"
                    />
                    {errors.provider_email && (
                      <span className="input-error">
                        {errors.provider_email}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={handlecompanyProviderClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyDetailsModal}>
        <ModalHeader>Comapny Provider Token</ModalHeader>

        <ModalBody>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>
            Provider Token:
            <span style={{ color: "gray" }}>
              {" "}
              {providerToken.provider_api_token}
            </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isModifyButtonLoad}
            outline
            onClick={onDetailsModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this vehicle?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square bg-danger border-0`}
              onClick={onDestroyRole}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CompanyProvider;
