import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import { getAlarmList } from "../../services/AlarmService";
import moment from "moment";
import { getVehicleList } from "../../services/VehicleService";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const tableLengthList = configDb.data.dataTableLength;

const Alarm = () => {
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const allPermissionsList = useSelector((x) => x.permission.value);
  const navigate = useNavigate();

  const [alarmList, setAlarmList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicle, setVehicle] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    vehicleId: vehicle.vehicleId,
    page: 1,
    search: "",
    is_reload: false,
    alarm_time: "",
    // filterTime: "",
  });

  useEffect(() => {
    let data = params;
    setIsLoading(true);
    getAlarmList(data)
      .then((response) => {
        setTotalRecords(response.data.total);
        setAlarmList(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(Utils.getErrorMessage(err));
      });
  }, [params]);

  useEffect(() => {
    let data = params;
    getVehicleList(data)
      .then((response) => {
        response = response.data;
        let list = response.data.map((vehicle) => ({
          value: vehicle.id,
          label: vehicle.vehicleName,
        }));
        setVehicleList(list);
      })
      .catch((err) => {});
  }, [params]);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };

    const getValue = (e) => (e ? (e.target ? e.target.value : e.value) : null);

    if (type === "length") {
      paramsObj.limit = getValue(e);
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = getValue(e);
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "date") {
      paramsObj.page = 1;
      paramsObj.alarm_time = getValue(e);
    } else if (type === "vehicle") {
      paramsObj.page = 1;
      paramsObj[type === "vehicle" ? "vehicleId" : ""] = getValue(e);
    }

    setParams(paramsObj);
  };
  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Alarm</h5>
        </div>
      </div>

      <div className="container-event">
        <div
          className="page-header-row company-section-Area"
          style={{ marginBottom: "8px" }}
        >
          <div className="row">
            <div className="col">
              <div className="row company-section">
                <div className="col-md-12">
                  <div className="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
                    <div className="col">
                      <div className="selected-block">
                        <p>Show</p>
                        <select
                          className="form-select form-control-show"
                          aria-label="Events Only"
                          defaultValue={params.limit}
                          onChange={(e) => handleParams(e, "length")}
                        >
                          {tableLengthList &&
                            tableLengthList.map((item, i) => (
                              <option value={item} key={i}>
                                {item}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col">
                      <div className="selected-block selected-mobile">
                        <p>Date</p>
                        <div className="input-group date">
                          <input
                            type="date"
                            className="form-control form-control-date"
                            placeholder="Today"
                            value={params.alarm_time}
                            onChange={(e) => handleParams(e, "date")}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="col">
                      <div className="selected-block selected-mobile">
                        <p>Time</p>
                        <div className="input-group time">
                          <input
                            type="time"
                            className="form-control"
                            value={params.filterTime}
                            onChange={(e) => handleParams(e, "filterTime")}
                            placeholder="Time"
                            style={{
                              marginBottom: "-15px",
                              marginLeft: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="col">
                      <div className="selected-block selected-mobile">
                        <p>Vehicle</p>
                        <div style={{ width: "100%" }}>
                          <Select
                            options={vehicleList}
                            value={vehicleList?.find(
                              (option) => option.value === params.vehicleId
                            )}
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                setVehicle((prevData) => ({
                                  ...prevData,
                                  vehicleId: selectedOption.value,
                                }));
                                handleParams(selectedOption, "vehicle");
                              } else {
                                setVehicle((prevData) => ({
                                  ...prevData,
                                  vehicleId: null,
                                }));
                                handleParams(null, "vehicle");
                              }
                            }}
                            placeholder="Select Vehicle"
                            isClearable={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="selected-block selected-mobile">
                        <p> Search</p>
                        <div className="search-bar">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`}>#</th>
                <th scope={`col`}>{`Device IMEI`}</th>
                <th scope={`col`}>{`Driver Name`}</th>
                <th scope={`col`}>{`Vehicle Name`}</th>
                <th scope={`col`}>{`Alarm Time`}</th>
                <th scope={`col`}>{`Notification Type`}</th>
              </tr>
            </thead>
            <tbody>
              {alarmList &&
                alarmList?.data?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ cursor: "pointer" }}>
                      {item.device_imei || "-"}
                    </td>
                    <td style={{ cursor: "pointer" }}>
                      {item.driver_name || "-"}
                    </td>
                    <td style={{ cursor: "pointer" }}>
                      {item.vehicle_name || "-"}
                    </td>
                    <td style={{ cursor: "pointer" }}>
                      {moment(item.alarm_time).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                    <td
                      style={{ cursor: "pointer", color: "#45abe3" }}
                      onClick={() =>
                        navigate("/dashboard", { state: { item } })
                      }
                    >
                      {item.alarm_type || "OverSpeed" || "-"}:{" "}
                      {item.vehicle_plate_no}
                    </td>
                  </tr>
                ))}
              {alarmList && alarmList?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={
                      allPermissionsList.findIndex(
                        (e) => e.name == "modify_vehicle"
                      ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_vehicle"
                      ) > -1
                        ? 6
                        : 3
                    }
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>
    </>
  );
};

export default Alarm;
