import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";

const DailyTachnoDetails = ({ TachnoDriverDetailsList }) => {

  const [hoveredInfo, setHoveredInfo] = useState(null);
  console.log("hoveredInfo",hoveredInfo)

  const svgContainerRef = useRef(null);

  const handleMouseEnter = (date) => {
    // const dayInfo = item?.days?.find((day) => day.date === date);
    // setHoveredInfo(dayInfo);
  };

  const handleMouseLeave = () => {
    setHoveredInfo(null);
  };

  useEffect(() => {
    if (svgContainerRef.current) {
      const rectElements = svgContainerRef.current.querySelectorAll("rect");

      rectElements.forEach((rect) => {
        rect.addEventListener("mouseenter", (e) => {
          handleMouseEnter(e.target);
        });

        rect.addEventListener("mouseleave", handleMouseLeave);
      });

      return () => {
        rectElements.forEach((rect) => {
          rect.removeEventListener("mouseenter", (e) => {
            handleMouseEnter(e.target);
          });

          rect.removeEventListener("mouseleave", handleMouseLeave);
        });
      };
    }
  }, [handleMouseEnter, handleMouseLeave]);

  return (
    <div>
      <div className="card card-primary general-ac tachnoCard">
        {/* <h4>
          {TachnoDriverDetailsList?.driver?.name}{" "}
          {TachnoDriverDetailsList?.period?.startDate} to{" "}
          {TachnoDriverDetailsList?.period?.endDate}
        </h4> */}

        {/* <p>
          {TachnoDriverDetailsList?.activityAnalysis?.weeks?.map((item) => {
            const fixedSvgString = item?.graph?.replace(/\\"/g, '"');

            return (
              <>
                {item.graph && (
                  <div>
                    <h4>
                      Week From {item.startDate} {item.endDate}
                    </h4>
                    <div
                      className="tab-pane fade show active"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabIndex={0}
                    >
                      <div className="tabContent">
                        <div className="driving">
                          <span />
                          <p>Driving({item.metrics.totalDriving})</p>
                        </div>
                        <div className="driving">
                          <span className="colorGreen" />
                          <p>Other Works ({item.metrics.totalWork})</p>
                        </div>
                        <div className="driving">
                          <span className="colorYellow" />
                          <p>Available ({item.metrics.totalAvailable})</p>
                        </div>
                        <div className="driving">
                          <span className="colorGrey" />
                          <p>Break ({item.metrics.totalBreak})</p>
                        </div>
                        <div className="driving">
                          <span className="colorWhite" />
                          <p>Unknown ({item.metrics.totalUnknown})</p>
                        </div>
                        <div className="driving">
                          <span className="colorBlue" />
                          <p>Amplitude ({item.metrics.totalAmplitude})</p>
                        </div>
                      </div>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: fixedSvgString }}
                      ref={svgContainerRef}
                    />

                    <div className="driving">
                      <p>Infringements detected</p>
                      {item?.days?.map((items) => {
                        return (
                          <>
                            {items.infringements?.map((rows) => {
                              return (
                                <>
                                  <p>
                                    {rows.severityLevel === "very_serious" ? (
                                      <>
                                        <div
                                          style={{
                                            background: "red",
                                            color: "white",
                                          }}
                                        >
                                          Incorrect switching
                                          {moment(rows.dateTime).format(
                                            "DD/MM/YYYY HH:mm"
                                          )}{" "}
                                          Fine From {rows.fineMin}€ to{" "}
                                          {rows.fineMax}
                                          {rows.severityLevel}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          style={{
                                            background: "#ffda00",
                                            color: "white"
                                          }}
                                        >
                                          Incorrect switching
                                          {moment(rows.dateTime).format(
                                            "DD/MM/YYYY HH:mm"
                                          )}{" "}
                                          Non-compliance with rest intervals
                                          during the daily work period
                                          {rows.fineMin}€ to {rows.fineMax}
                                          {rows.severityLevel}
                                        </div>
                                      </>
                                    )}
                                  </p>
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                    </div>

                    {hoveredInfo && (
                      <div className="hover-info">
                        {hoveredInfo?.days?.map((dayItem, index) => (
                          <div key={index}>
                            <div>{dayItem.date}</div>
                            <div>{dayItem.metrics.totalDriving}</div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </>
            );
          })}
        </p> */}

        <p>
          {TachnoDriverDetailsList?.activityAnalysis?.weeks?.map((item) => {
            return (
              <>
                {item?.days?.map((items) => {

                  const fixedSvgString = items?.graph?.replace(/\\"/g, '"');

                  return (
                    <>
                      {items?.graph && (
                        <div>
                          <h4>{items.date}</h4>

                          <div
                            className="tab-pane fade show active"
                            id="home-tab-pane"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                            tabIndex={0}
                          >
                            <div className="tabContent">
                              <div className="driving">
                                <span />
                                <p>Driving({items.metrics.totalDriving})</p>
                              </div>
                              <div className="driving">
                                <span className="colorGreen" />
                                <p>Other Works ({items.metrics.totalWork})</p>
                              </div>
                              <div className="driving">
                                <span className="colorYellow" />
                                <p>
                                  Available ({items.metrics.totalAvailable})
                                </p>
                              </div>
                              <div className="driving">
                                <span className="colorGrey" />
                                <p>Break ({items.metrics.totalBreak})</p>
                              </div>
                              <div className="driving">
                                <span className="colorWhite" />
                                <p>Unknown ({items.metrics.totalUnknown})</p>
                              </div>
                              <div className="driving">
                                <span className="colorBlue" />
                                <p>
                                  Amplitude ({items.metrics.totalAmplitude})
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="svgSize"
                            dangerouslySetInnerHTML={{ __html: fixedSvgString }}
                            // ref={svgContainerRef}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            );
          })}
        </p>
      </div>
    </div>
  );
};

export default DailyTachnoDetails;
