import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";
import ConfigDB from "../../config";
import { getVehicleList } from "../../services/VehicleService";
import Select from "react-select";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { createAddDriver } from "../../services/EmployeeService";
import validator from "validator";

const GeneralDriver = ({ setActiveTab, driverDetails }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;

  const [driverData, setDriverData] = useState({
    uuid: "",
    name: "",
    vehicle_id: "",
    RFID_iButton: "",
    number: "",
    email: "",
    notes: "",
  });

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [vehicleList, setVehicleList] = useState([]);
  const fileInputRef = React.createRef();
  const [fileName, setFileName] = useState("");

  let errorsObj = { number: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [error, setError] = useState("");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  useEffect(() => {
    if (driverDetails) {
      setDriverData({
        uuid: driverDetails?.uuid,
        name: driverDetails?.name,
        vehicle_id: driverDetails?.vehicle_id,
        RFID_iButton: driverDetails?.RFID_ibutton,
        number: driverDetails?.number,
        email: driverDetails?.email,
        notes: driverDetails?.notes,
      });
      setImagePreview(driverDetails?.userImage);
    }
  }, [driverDetails]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];

      if (!allowedTypes.includes(file.type)) {
        setError("Please select a PNG, JPG, or JPEG file.");
        return;
      }

      setError("");

      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
      setFileName(file.name);

      setImage(file);
    }
  };

  const handleRemoveImage = () => {
    setImagePreview("");
    setError("");
    setFileName("");
    fileInputRef.current.value = "";
  };

  useEffect(
    function () {
      let data = params;
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    if (!validator.isMobilePhone(driverData.number)) {
      errorObj.number = "Invalid contact number";
      error = true;
    } else if (driverData.number && driverData.number.length !== 9) {
      errorObj.number = "Invalid contact number";
      error = true;
    }

    setErrors(errorObj);

    if (error) return;

    const formData = new FormData();
    formData.append("files", image);
    formData.append("userRole", 4);

    formData.append("name", driverData.name);
    formData.append("vehicle_id", driverData?.vehicle_id);
    formData.append("RFID_iButton", driverData?.RFID_iButton);
    formData.append("number", driverData?.number);
    formData.append("email", driverData?.email);
    formData.append("notes", driverData?.notes);

    const formDatas = new FormData();
    formDatas.append("files", image);
    formDatas.append("userRole", 4);
    formDatas.append("uuid", driverData?.uuid);
    formDatas.append("name", driverData.name);
    formDatas.append("vehicle_id", driverData?.vehicle_id);
    formDatas.append("RFID_iButton", driverData?.RFID_iButton);
    formDatas.append("number", driverData?.number);
    formDatas.append("email", driverData?.email);
    formDatas.append("notes", driverData?.notes);

    if (driverData?.uuid) {
      modifyDriver(createAddDriver, formDatas);
    } else {
      modifyDriver(createAddDriver, formData);
    }
  };

  const modifyDriver = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;

        localStorage.setItem("driverId", response.data.data.uuid);
        localStorage.setItem("driverIds", response.data.data.id);

        setParams(dataParams);
        setActiveTab("professonal");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <h4>Identification</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-12" style={{ marginTop: "10px" }}>
                    <div className="input-div">
                      <input
                        className="form-control input-upload"
                        type="file"
                        accept="image/jpeg, image/png"
                        onChange={handleImageChange}
                        style={{ paddingLeft: "12px" }}
                        ref={fileInputRef}
                      />
                      {error && <div className="text-danger mt-2">{error}</div>}
                      {imagePreview && (
                        <div className="image-preview-container mt-2">
                          <img
                            src={imagePreview}
                            alt="Preview"
                            style={{ maxWidth: "100%", maxHeight: "200px" }}
                            className="img-thumbnail"
                          />
                        </div>
                      )}
                      <Button
                        color="danger"
                        onClick={handleRemoveImage}
                        className="mt-2 btn-delete bg-danger border-0 text-light"
                      >
                         Delete
                      </Button>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Name"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={driverData.name}
                        onChange={(e) => {
                          setDriverData({
                            ...driverData,
                            name: e.target.value,
                          });
                        }}
                        name={`name`}
                        placeholder="Name"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Vehicle"}</Label>
                    <div className="input-div">
                      <Select
                        options={vehicleList}
                        value={vehicleList?.find(
                          (option) =>
                            option.value ===
                            JSON.stringify(driverData.vehicle_id)
                        )}
                        onChange={(selectedOption) =>
                          setDriverData((prevData) => ({
                            ...prevData,
                            vehicle_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Vehicle"}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"RFID Button"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={driverData.RFID_iButton}
                        onChange={(e) => {
                          setDriverData({
                            ...driverData,
                            RFID_iButton: e.target.value,
                          });
                        }}
                        name={`RFID_iButton`}
                        placeholder="RFID Button"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Mobile Phone"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={driverData.number}
                        onChange={(e) => {
                          setDriverData({
                            ...driverData,
                            number: e.target.value,
                          });
                        }}
                        name={`number`}
                        placeholder="Mobile Phone"
                        maxLength={9}
                      />
                    </div>
                    {errors.number && (
                      <span className="input-error">{errors.number}</span>
                    )}
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Email"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={driverData.email}
                        onChange={(e) => {
                          setDriverData({
                            ...driverData,
                            email: e.target.value,
                          });
                        }}
                        name={`email`}
                        placeholder="Email"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Notes"}</Label>
                    <div className="input-div">
                      <textarea
                        className="form-control"
                        type="text"
                        value={driverData.notes}
                        onChange={(e) => {
                          setDriverData({
                            ...driverData,
                            notes: e.target.value,
                          });
                        }}
                        name={`notes`}
                        placeholder="Notes"
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralDriver;
