import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import { getFuelManagementReportList } from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import * as XLSX from "xlsx";

const FuelMangementList = () => {
  const [alarmsDetails, setAlarmsDetails] = useState("");
  const [alarmsDetailsList, setAlarmsDetailsList] = useState([]);
  const storedData = localStorage.getItem("FuelMangementReportData");
  const parsedData = JSON.parse(storedData);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const alarms = event.data;
      setAlarmsDetails(alarms);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    // if (alarmsDetails) {
    let data = {
      vehicle_id: parsedData?.vehicle_id,
      startDate: parsedData?.startDate,
      include_chronometer: parsedData?.include_chronometer,
      include_engine_hr: parsedData?.include_engine_hr,
      one_row_per_Day: parsedData?.one_row_per_Day,
    };
    getFuelManagementReportList(data)
      .then((response) => {
        setAlarmsDetailsList(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
    // }
  }, []);

  const exportToExcel = () => {
    if (!alarmsDetailsList || Object.keys(alarmsDetailsList).length === 0) {
      alert("No data to export!");
      return;
    }

    const dataToExport = [alarmsDetailsList];

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alarms");

    XLSX.writeFile(workbook, "fueal_manegement_details.xlsx");
  };
  return (
    <div>
      <h1>Fuel Mangement Reports</h1>
      <>
        <div className="tablePage">
          <div className="container-fluid">
            <div className="tablePageMain mt-2">
              <div className>
                <div className="logoimg">
                  <img src={Logo} alt="logoimg" />
                </div>
              </div>
              <div className>
                <div className="Content">
                  <div>
                    <h3>Fuel Mangement</h3>
                    <p>{parsedData?.startDate}</p>
                    <h5 style={{ cursor: "pointer" }} onClick={exportToExcel}>
                      Export to Excel
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-table mt-4">
              <table className="table table-striped table-hover">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Fuel Level Start</th>
                    <th scope="col">Fuel Level End</th>
                    <th scope="col">Total Fuel Used</th>
                    <th scope="col">Total Distance</th>
                    <th scope="col">Average Consumption</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{alarmsDetailsList?.fuelLevelStart}</td>
                    <td>{alarmsDetailsList?.fuelLevelEnd}</td>
                    <td>{alarmsDetailsList?.totalFuelUsed}</td>
                    <td>{alarmsDetailsList?.totalDistance}</td>
                    <td>{alarmsDetailsList?.averageConsumption}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default FuelMangementList;
