import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import { actionDelVehicle } from "../../services/VehicleService";
import {
  createAccount,
  createAccountLicenses,
  destroyAccountLicenses,
  getAccountLicensesList,
  getAccountList,
} from "../../services/ManagementService";

import TimePicker from "react-time-picker";
import moment from "moment/moment";

const tableLengthList = configDb.data.dataTableLength;

const LicenseClass = () => {
  const navigate = useNavigate();
  const allPermissionsList = useSelector((x) => x.permission.value);
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const [vehicleList, setVehicleList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [vehicleData, setVehicleData] = useState({
    licenses_class: "",
  });
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [DelLicense, setDelLicense] = useState({});

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getAccountLicensesList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setVehicleList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const onAddSubscription = (e) => {
    e.preventDefault();
    setVehicleData({
      licenses_class: "",
    });
    setModifyModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelLicense("");
    setDelModal(false);
  };

  const onDeleteLicenses = (e, item) => {
    e.preventDefault();
    setDelLicense(item.uuid);
    setDelModal(true);
  };

  const onDestroyRole = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: DelLicense };
    destroyAccountLicenses(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelLicense("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let data = {
      licenses_class: vehicleData?.licenses_class,
    };

    let updateData = {
      licenses_class: vehicleData?.licenses_class,
      uuid: vehicleData.uuid,
    };

    if (vehicleData?.uuid) {
      modifySubscription(createAccountLicenses, updateData);
    } else {
      modifySubscription(createAccountLicenses, data);
    }
  };

  const modifySubscription = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setVehicleData({
          licenses_class: "",
        });
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const onModifyModalClose = (e) => {
    setVehicleData({
      licenses_class: "",
      period: "",
      additional_period: "",
    });
    setModifyModal(false);
  };

  const onEditSubscription = (e, item) => {
    e.preventDefault();
    setVehicleData({
      uuid: item.uuid,
      licenses_class: item?.licenses_class,
    });

    setModifyModal(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="inner-header py-2 mt-4">
            <div className="left-block">
              <h5 className="d-none">Working Period</h5>
            </div>

            <div className="right-block w-auto">
              {allPermissionsList.findIndex((e) => e.name == "modify_vehicle") >
                -1 && (
                <Button
                  type={`button`}
                  onClick={onAddSubscription}
                  className={`add-btn btn  float-right`}
                >
                  {`Add`}
                  <i className="plus-circle">
                    <img src={plusIcon} alt="" className="plus-icons" />
                  </i>
                </Button>
              )}
            </div>
          </div>

          <div className="container-event">
            <div className="page-header-row">
              <div className="row">
                <div className="col">
                  <div className="row d-flex justify-content-between company-section">
                    
                  </div>
                </div>
              </div>
            </div>

            <div className="page-table-row general-ac">
              <table
                className={`table table-striped ${isLoading ? "loading" : ""}`}
                width="100%"
              >
                <thead>
                  <tr>
                    <th scope={`col`}>{`Licenses Class`}</th>
                    <th scope={`col`}>{`Valid Licenses`}</th>
                    <th scope={`col`}>{`Expired Licenses`}</th>
                    <th scope={`col`}>{`Updated On`}</th>
                    <th scope={`col`}>{`UserName`}</th>
                    <th scope={`col`}>{`Action`}</th>
                  </tr>
                </thead>
                <tbody>
                  {vehicleList &&
                    vehicleList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td style={{ cursor: "pointer" }}>
                            {item.licenses_class || "-"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.valid_licenses}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.expired_licenses}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {moment(item.updated_on).format("MM/DD/YYYY")}
                          </td>
                          <td style={{ cursor: "pointer" }}>{JsonParse.name}</td>
                          <td style={{ cursor: "pointer" }}>
                            <button
                              type={`button`}
                              onClick={(e) => onEditSubscription(e, item)}
                              className={`btn btn-info add-btn-circle me-2`}
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </button>

                            <button
                              type={`button`}
                              onClick={(e) => onDeleteLicenses(e, item)}
                              className={`btn btn-circle btn-danger add-btn-circle me-2`}
                            >
                              <i className="bi bi-trash-fill"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}

                  {vehicleList && vehicleList?.data?.length === 0 && (
                    <tr>
                      <td
                        colSpan={
                          allPermissionsList.findIndex(
                            (e) => e.name == "modify_vehicle"
                          ) > -1 ||
                          allPermissionsList.findIndex(
                            (e) => e.name == "delete_vehicle"
                          ) > -1
                            ? 7
                            : 7
                        }
                        className={`text-center`}
                      >{`Record Not Found`}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={`float-right`}>
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Create LicenseClass</ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleForm}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">
                {"License Class"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.licenses_class}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      licenses_class: e.target.value,
                    });
                  }}
                  name={`licenses_class`}
                  maxLength={50}
                  placeholder="License Class"
                />
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this license class?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square bg-danger border-0`}
              onClick={onDestroyRole}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default LicenseClass;
