import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import {
  UpdateTemplateTrip,
  actionAddUpdateTrip,
  actionUpdateTrip,
  getTripDetails,
} from "../../services/TripService";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { getVehicleList } from "../../services/VehicleService";
import ConfigDB from "../../config";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import { getSettingDetails } from "../../services/SettingService";
import { DirectionsService } from "@react-google-maps/api";

const tableLengthList = ConfigDB.data.dataTableLength;

const vehicleSchduleType = [
  { value: 1, label: "Daily" },
  { value: 2, label: "Weekly" },
  { value: 3, label: "Monthly" },
];

const AddVehicle = ({ setAddTemplateStatus, TemplateStatusData }) => {
  const [activeTab, setActiveTab] = useState("general");
  const [tripDetails, setTripDetails] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [origin, setOrigin] = useState(null);
  const [dates, setDates] = useState("");

  const [vehicle, setVehicle] = useState("");
  const [scheduleType, setScheduleType] = useState("");

  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState(0);
  const [time, setTime] = useState(0);
  const [price, setPrice] = useState("");
  const [directions, setDirections] = useState(null);

  const [vehicleList, setVehicleList] = useState([]);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const [destination, setDestination] = useState(null);
  const [settingDetails, setsettingDetails] = useState([]);

  const navigate = useNavigate();
  const mapRef = React.createRef();

  useEffect(
    function () {
      let data = params;
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const panTo = useCallback(async ({ lat, lng, zoom }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(14);
    }
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setOrigin({ lat, lng, placeName });
  }, []);

  const panToDestination = useCallback(async ({ lat, lng, zoom }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(14);
    }
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setDestination({ lat, lng, placeName });
  }, []);

  const handleChangeDate = (date) => {
    setDates(date);
  };

  useEffect(() => {
    if (TemplateStatusData?.tripName?.uuid) {
      const fetchTripDetails = async () => {
        const data = {
          uuid: TemplateStatusData?.tripName?.uuid,
          date: TemplateStatusData.date,
          trip_id: TemplateStatusData.trip_id,
        };

        setIsLoading(true);
        try {
          const response = await getTripDetails(data);
          setTripDetails(response.data.data.tripData);
        } catch (err) {
          toast.error(Utils.getErrorMessage(err));
        } finally {
          setIsLoading(false);
        }
      };

      fetchTripDetails();
    }
  }, [TemplateStatusData?.tripName?.uuid]);

  useEffect(() => {
    if (TemplateStatusData) {
      const sourcePlace = TemplateStatusData?.tripName?.source_place;
      const destinationPlace = TemplateStatusData?.tripName?.destination_place;

      if (sourcePlace) {
        setOrigin({
          lat: TemplateStatusData?.tripName?.source_latitude,
          lng: TemplateStatusData?.tripName?.source_longitude,
          placeName: sourcePlace,
        });
        panTo({
          lat: TemplateStatusData?.tripName?.source_latitude,
          lng: TemplateStatusData?.tripName?.source_longitude,
        });
      }

      if (destinationPlace) {
        setDestination({
          lat: TemplateStatusData?.tripName.destination_latitude,
          lng: TemplateStatusData?.tripName.destination_longitude,
          placeName: destinationPlace,
        });
        panToDestination({
          lat: TemplateStatusData?.tripName.destination_latitude,
          lng: TemplateStatusData?.tripName.destination_longitude,
        });
      }

      setVehicle(TemplateStatusData.vehicle_id);
    }
  }, [TemplateStatusData, panTo, panToDestination]);

  useEffect(() => {
    if (TemplateStatusData?.date) {
      setDates(TemplateStatusData?.date);
    }
  }, [TemplateStatusData?.date]);

  useEffect(
    function () {
      let data = params;
      getSettingDetails(data)
        .then((response) => {
          setsettingDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const calculatePrice = (totalDistance, totalDuration) => {
    const distanceRate =
      settingDetails?.length > 0 ? settingDetails[0].price_per_distance : 0;

    const durationRate =
      settingDetails?.length > 0 ? settingDetails[0].price_per_hour : 0;

    const distancePrice = parseFloat((totalDistance * distanceRate).toFixed(2));
    const durationPrice = parseFloat(
      ((totalDuration * durationRate) / 100).toFixed(2)
    );

    let totalPrice =
      distancePrice > durationPrice ? distancePrice : durationPrice;

    return {
      price: totalPrice,
      description:
        distancePrice > durationPrice
          ? `distance: $${distancePrice.toFixed(2)}`
          : `duration: $${durationPrice.toFixed(2)}`,
    };
  };

  const date = new Date(dates);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const directionsCallback = useCallback(
    (result, status) => {
      if (result !== null && status === "OK") {
        const allRouteDetails = [];

        result.routes.forEach((route) => {
          let totalDistance = 0;
          let totalDuration = 0;
          const legs = route.legs.map((leg) => ({
            distance: leg.distance.value,
            duration: leg.duration.value,
            sourcePlace: leg.start_address,
            destinationPlace: leg.end_address,
            startLocation: {
              latitude: leg.start_location.lat(),
              longitude: leg.start_location.lng(),
            },
            endLocation: {
              latitude: leg?.end_location.lat(),
              longitude: leg?.end_location.lng(),
            },
          }));

          legs.forEach((leg) => {
            totalDistance += leg.distance;
            totalDuration += leg.duration;
          });

          allRouteDetails.push({
            totalDistance: (totalDistance / 1000).toFixed(2),
            totalDuration,
            totalDurationText: `${Math.floor(
              totalDuration / 3600
            )}h:${Math.floor((totalDuration % 3600) / 60)}m`,
            legs,
          });
          const totalDistanceInKm = (totalDistance / 1000).toFixed(2);
          let price = calculatePrice(totalDistanceInKm, totalDuration);
          setPrice(price.price);
        });

        if (allRouteDetails?.length > 0) {
          const firstRoute = allRouteDetails[0];

          setDistance(firstRoute.totalDistance);
          setDuration(firstRoute.totalDurationText);
          const currentTime = new Date();
          const estimatedArrivalTime = new Date(
            currentTime.getTime() + firstRoute.totalDuration * 1000
          );

          const options = { hour: "2-digit", minute: "2-digit", hour12: false };
          const formattedArrivalTime = estimatedArrivalTime.toLocaleTimeString(
            [],
            options
          );

          const isToday =
            estimatedArrivalTime.toDateString() === currentTime.toDateString();
          const arrivalMessage = isToday
            ? `Today, ${formattedArrivalTime}`
            : estimatedArrivalTime.toLocaleString([], {
                weekday: "long",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              });
        }

        setDirections(result);
      }
    },
    [settingDetails]
  );

  const directionsOptions = useMemo(() => {
    return {
      destination,
      origin,
      travelMode: "DRIVING",
      avoidTolls: true,
      provideRouteAlternatives: true,
    };
  }, [origin, destination]);

  useEffect(() => {
    if (directions) {
      const allRouteDetails = [];
      directions.routes.forEach((route) => {
        let totalDistance = 0;
        let totalDuration = 0;

        route.legs.forEach((leg) => {
          totalDistance += leg.distance.value;
          totalDuration += leg.duration.value;
        });

        allRouteDetails.push({
          totalDistance: (totalDistance / 1000).toFixed(2),
          totalDuration,
          totalDurationText: `${Math.floor(totalDuration / 3600)}h:${Math.floor(
            (totalDuration % 3600) / 60
          )}m`,
        });
      });

      if (allRouteDetails.length > 0) {
        const firstRoute = allRouteDetails[0];
        setDistance(firstRoute.totalDistance);
        setDuration(firstRoute.totalDurationText);

        const currentTime = new Date();
        const estimatedArrivalTime = new Date(
          currentTime.getTime() + firstRoute.totalDuration * 1000
        );

        const options = { hour: "2-digit", minute: "2-digit", hour12: false };
        const formattedArrivalTime = estimatedArrivalTime.toLocaleTimeString(
          [],
          options
        );

        const isToday =
          estimatedArrivalTime.toDateString() === currentTime.toDateString();
        const arrivalMessage = isToday
          ? `Today, ${formattedArrivalTime}`
          : estimatedArrivalTime.toLocaleString([], {
              weekday: "long",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            });

        // Optionally set the arrival message in state if needed
        // setArrivalMessage(arrivalMessage);
      }
    }
  }, [directions]);

  const handleStartTripForm = (e) => {
    e.preventDefault();
    let data = {
      id: TemplateStatusData?.trip_id,
      sourcePlace: origin?.placeName,
      sourceLatitude: origin?.lat,
      sourceLongitude: origin?.lng,
      destinationPlace: destination?.placeName,
      destinationLatitude: destination?.lat,
      destinationLongitude: destination?.lng,
      totalDistance: distance,
      totalDuration: time,
      trip_name: "Trip1",
      price: price || 0,
      vehicleId: vehicle.vehicleId,
      date: formattedDate,
      schedule_type: scheduleType?.schedule_type,
    };

    UpdateTemplateTrip(data)
      .then((response) => {
        toast.success(response.data.message);
        // navigate("/trip");
        setAddTemplateStatus(false);
      })

      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      });
  };

  return (
    <div>
      <div className="inner-header py-3 d-none">
        <div className="left-block">
          <h5></h5>
        </div>
      </div>
      <div className="inner-header-back">
        <div className="left-col">
          <button
            type="buttton"
            className="back-btn"
            onClick={() => setAddTemplateStatus(false)}
          >
            <i class="bi bi-chevron-left"></i> Back
          </button>
        </div>
        <div className="tight-block"></div>
      </div>

      <div className="leaflet-control-management managementui-tab leaflet-control-var">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "general" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("general");
                }}
              >
                <span data-href="#tab-1">General</span>
              </li>
            </ul>
          </nav>

          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "general" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <div className="row">
                <div className="col-md-12">
                  <DirectionsService
                    options={directionsOptions}
                    callback={directionsCallback}
                  />
                  <div className="card card-primary general-ac">
                    <Form method={`post`} onSubmit={handleStartTripForm}>
                      <FormGroup>
                        <div className="add-vehicle-rw">
                          <div className="row">
                            <div className="col-md-4">
                              <Label className="col-form-label">
                                {"Source Place"}
                              </Label>
                              <div className="input-div">
                                <Search panTo={panTo} />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <Label className="col-form-label">
                                {"Destination Place"}
                              </Label>
                              <div className="input-div">
                                <SearchDestination
                                  panToDestination={panToDestination}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <Label className="col-form-label">
                                {"Vehicle"}
                              </Label>
                              <div className="input-div">
                                <Select
                                  options={vehicleList}
                                  value={vehicleList?.find(
                                    (option) => option.value === vehicle
                                  )}
                                  onChange={(selectedOption) =>
                                    setVehicle((prevData) => ({
                                      ...prevData,
                                      vehicleId: selectedOption.value,
                                    }))
                                  }
                                  placeholder={"Select Vehicle"}
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <Label className="col-form-label">{"Date"}</Label>
                              <div className="input-div">
                                <DatePicker
                                  selected={dates}
                                  onChange={handleChangeDate}
                                  dateFormat="yyyy-MM-dd"
                                  calendarPosition="bottom-center"
                                  placeholder="Select Date"
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    borderRadius: "5px",
                                    height: "40px",
                                    margin: "1px 0",
                                    padding: "2px 5px",
                                    width: "301px",
                                    paddingRight: "30px",
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <Label className="col-form-label">
                                {"Schedule Type"}
                              </Label>
                              <div className="input-div">
                                <Select
                                  options={vehicleSchduleType}
                                  value={vehicleSchduleType?.find(
                                    (option) => option.value === scheduleType
                                  )}
                                  onChange={(selectedOption) =>
                                    setScheduleType((prevData) => ({
                                      ...prevData,
                                      schedule_type: selectedOption.value,
                                    }))
                                  }
                                  placeholder={"Select Schedule Type"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          color="success btn-info"
                          type={`submit`}
                          className={`btn-square `}
                        >{`Submit`}</Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Search = ({ panTo, setOrigin }) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      if (results.length === 0) {
        throw new Error("No results found");
      }
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      setOrigin({ lat, lng, time: new Date() });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup style={{ padding: "10px", paddingBottom: "0" }}>
          <Input
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

const SearchDestination = ({ panToDestination, setDestination }) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panToDestination({ lat, lng });

      setDestination({ lat, lng, time: new Date() });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup
          style={{
            padding: "10px",
            marginBottom: "0rem !important",
          }}
        >
          <Input
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default AddVehicle;
