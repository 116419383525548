import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ConfigDB from "../../config";
import DatePicker from "react-multi-date-picker";
import Select from "react-select";
import { getVehicleList } from "../../services/VehicleService";
import { getDriverList } from "../../services/TripService";
import {
  getFixedAllCostList,
  getVariableAllCostList,
} from "../../services/ManagementService";
import { DateRangePicker } from "react-date-range";
import { actionDailyDriverReports } from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";

const ShowInformationType = [
  { value: "1", label: "Driver" },
  { value: "2", label: "Driver Status" },
];

const DriverDiary = ({ setActiveTab }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const [dateRange, setDateRange] = useState([null, null]);

  const handleChange = (range) => {
    setDateRange(range);
  };
  const [driverDiaryData, setDriverDiaryData] = useState({
    show_information_by: "",
    vehicle_id: "",
    driver_id: "",
    auth_formate: 0,
  });

  const [modifyModal, setModifyModal] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  const [fixedCosAlltList, setfixedAllCostList] = useState([]);
  const [variableCostAllList, setvariableCostAllList] = useState([]);
  const [DriverList, setDriverList] = useState([]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [open, setOpen] = useState(false);

  const formattedStartDate = selectionRange.startDate.toLocaleDateString();
  const formattedEndDate = selectionRange.endDate.toLocaleDateString();

  const onDatepickerOpen = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const handleSendEmailOpen = () => {
    setModifyModal(true);
  };

  const handleSendEmailClose = () => {
    setModifyModal(false);
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDriverDiaryData({
      ...driverDiaryData,
      auth_formate: newValue,
    });
  };

  useEffect(
    function () {
      let data = params;
      getDriverList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setDriverList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = { ...params };
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  const date = new Date(dateRange);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const handleGenerate = (e) => {
    e.preventDefault();

    console.log("sasa");
    if (!dateRange) {
      toast.error("Please select date.");
      return;
    }

    // if (!driverDiaryData.driver_id) {
    //   toast.error("Please select Driver.");
    //   return;
    // }

    let data = {
      // driver_id: driverDiaryData?.driver_id,
      reportDate: formattedDate,
      auth_formate: driverDiaryData?.auth_formate,
    };

    modifyDailyReports(actionDailyDriverReports, data);
  };
  const modifyDailyReports = (actionFunction, data) => {
    actionFunction(data)
      .then((response) => {
        // toast.success(response.data.message);

        const dataToSend = {
          // driver_id: driverDiaryData?.driver_id,
          reportDate: formattedDate,
          auth_formate: driverDiaryData?.auth_formate,
        };

        localStorage.setItem("DriverDiaryData", JSON.stringify(dataToSend));
        // window.open("/daily-summary", "_blank");

        if (
          !response?.data ||
          response?.data?.data === null ||
          response?.data.data?.length <= 0
        ) {
          toast.error("No data found.");
          return;
        }
        window.open("/daily-driver-reports", "_blank");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleGenerate}>
              <h6>DRIVER DIARY</h6>
              <h6>
                This report lists the details of the driving times of a driver
                or drivers.
              </h6>
              <div className="d-flex justify-content-start">
                <div className="me-1">
                  <Button
                    color="success btn-info"
                    type={`submit`}
                    className={`btn-square`}
                    // onClick={handleGenerate}
                  >{`Generate`}</Button>
                </div>
                {/* <div>
                  <Button
                    color="success btn-info"
                    type={`button`}
                    onClick={handleSendEmailOpen}
                    className={`btn-square`}
                  >{`Send Report By EMail`}</Button>
                </div> */}
              </div>
            </Form>
          </div>
          <div className="card card-primary general-ac">
            <Form method={`post`}>
              <h6>Configuration</h6>
              <div className="row">
                <div className="col-md-4">
                  <FormGroup>
                    <Label className="col-form-label">{"Period"}</Label>

                    <DatePicker
                      selected={dateRange}
                      onChange={handleChange}
                      dateFormat="yyyy-MM-dd"
                      calendarPosition="bottom-center"
                      placeholder="Select Date"
                      style={{
                        border: "1px solid #CCCCCC",
                        borderRadius: "5px",
                        height: "40px",
                        margin: "1px 0",
                        padding: "2px 5px",
                        paddingRight: "30px",
                        width: "100%",
                      }}
                    />
                  </FormGroup>
                </div>

                {/* <div className="col-md-4">
                  <Label className="col-form-label">{"Driver"}</Label>

                  <Select
                    options={DriverList}
                    value={DriverList?.find(
                      (option) =>
                        option.value ===
                        JSON.stringify(driverDiaryData.driver_id)
                    )}
                    onChange={(selectedOption) =>
                      setDriverDiaryData((prevData) => ({
                        ...prevData,
                        driver_id: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Driver"}
                  />
                </div> */}

                <div className="col-md-4 mt-4">
                  <Label className="col-form-label">Authorities format</Label>
                  <div className="input-div">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="tachographTypeCheckbox"
                        checked={driverDiaryData.include_note}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="tachographTypeCheckbox"
                      >
                        Authorities format
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal isOpen={modifyModal}>
        <ModalHeader>Send Report By Email </ModalHeader>
        <Form method={`post`}>
          <ModalBody>
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <Label className="col-form-label">{"Subject"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      name={`subject`}
                      onChange={(e) => {
                        setDriverDiaryData({
                          ...driverDiaryData,
                          subject: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={driverDiaryData.subject}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Email"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      name={`email`}
                      onChange={(e) => {
                        setDriverDiaryData({
                          ...driverDiaryData,
                          email: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={driverDiaryData.email}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={handleSendEmailClose}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success btn-info"
              type={`submit`}
              className={`btn-square`}
            >{`Submit`}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default DriverDiary;
