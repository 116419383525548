import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import {
  getVehicleStopsReportList,
  getVehicleTripsReportList,
} from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import moment from "moment";
import * as XLSX from "xlsx";

const VehicleStopList = () => {
  const [alarmsDetails, setAlarmsDetails] = useState("");
  const [alarmsDetailsList, setAlarmsDetailsList] = useState([]);
  const storedData = localStorage.getItem("vehicleStopsReportData");
  const parsedData = JSON.parse(storedData);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const alarms = event.data;
      setAlarmsDetails(alarms);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    // if (alarmsDetails) {
    let data = {
      vehicle_id: parsedData?.vehicle_id,
      startDate: parsedData?.startDate,
    };
    getVehicleStopsReportList(data)
      .then((response) => {
        setAlarmsDetailsList(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
    // }
  }, []);

  const exportToExcel = () => {
    if (!alarmsDetailsList || alarmsDetailsList.length === 0) {
      toast.error("No data to export!");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(alarmsDetailsList);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alarms");

    XLSX.writeFile(workbook, "vehiclestops.xlsx");
  };

  return (
    <div>
      <div className="tablePage">
        <div className="container-fluid">
          <div className="tablePageMain mt-2">
            <div className>
              <div className="logoimg">
                <img src={Logo} alt="logoimg" />
              </div>
            </div>
            <div className>
              <div className="Content">
                <div>
                  <h3>Vehicle Trips</h3>
                  <h5 style={{ cursor: "pointer" }} onClick={exportToExcel}>
                    Export to Excel
                  </h5>
                </div>
              </div>
            </div>
          </div>
          {alarmsDetailsList?.length > 0 ? (
            <>
              <div class="tablePage">
                <div class="container-fluid">
                  <div class="tablePageMain mt-2 table-title">
                    <div>
                      <div class="logoimg">
                        <h5>Vehicle: {alarmsDetailsList[0]?.vehicle_name}</h5>
                      </div>
                    </div>
                    <div class="">
                      <div class="Content">
                        <div class="pe-3">
                          <h5>Driver: {alarmsDetailsList[0]?.driver_name}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  {alarmsDetailsList?.map((item, index) => {
                    return (
                      <>
                        <div class="table-titled bg-dark">
                          <div class="container-fluid text-center">
                            <div class="">
                              <div class="text-light text-center p-1 ">
                                Trips {index + 1}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="custom-table mt-4 table-responsive">
                          <table class="table table-striped table-hover">
                            <thead class="table-light">
                              <tr>
                                <th colspan="4">Departure</th>
                                <th colspan="4" class="text-center">
                                  Arrival
                                </th>
                                <th>Driver</th>
                                <th colspan="3" class="text-center">
                                  Duration
                                </th>
                                <th>Mileage(km)</th>
                                <th class="text-center">Cost</th>

                                <th class="text-center">Alarms</th>
                                <th class="text-center">Deptm.</th>
                                <th class="text-center">Segm.</th>
                              </tr>
                              <tr>
                                <th scope="col">Time</th>
                                <th scope="col">Place</th>
                                <th scope="col">Address</th>
                                <th scope="col">odometer(km)</th>
                                <th scope="col">Time</th>
                                <th scope="col">Place</th>
                                <th scope="col">Address</th>
                                <th scope="col">odometer(km)</th>
                                <th scope="col"></th>
                                <th scope="col">Driving</th>
                                <th>Idle</th>
                                <th scope="col">Total</th>
                                <th scope="col">Mileage (km)</th>
                                <th scope="col">Cost</th>

                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">
                                  {moment(item?.tripStartTime).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                  )}
                                </th>
                                <td>-</td>
                                <td>{item.departureName}</td>
                                <td>{item?.trip_odometer}</td>
                                <td>
                                  {moment(item?.tripEndTime).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                  )}
                                </td>
                                <td>-</td>
                                <td>{item.arrivalName}</td>
                                <td>{item?.trip_odometer}</td>
                                <td>{item?.driver_name}</td>
                                <td>{item?.tripDuration}</td>
                                <td></td>
                                <td>{item?.tripDuration}</td>
                                <td>{item?.tripKilometer}</td>
                                <td>{item.cost}</td>

                                <td class="bg-danger">
                                  {
                                    item.alarmType === "low_speed"
                                      ? item.lower_speed_limit // If it's low_speed, show lower_speed_limit
                                      : item.overSpeedLimit !== null // If overSpeedLimit is not null, show overSpeedLimit
                                      ? item.overSpeedLimit
                                      : "0" // If overSpeedLimit is null, show a default value (optional)
                                  }
                                </td>

                                <td>{item.department_name}</td>
                                <td>{item.segment_name}</td>
                              </tr>

                              {/* <tr>
                                <td colspan="9" class="bg-dark text-light">
                                  Total
                                </td>
                                <td class="bg-dark text-light">1:04</td>
                                <td class="bg-dark text-light">1:04</td>
                                <td class="bg-dark text-light">1:04</td>
                                <td class="bg-dark text-light">1:04</td>
                                <td class="bg-dark text-light">1:04</td>
                                <td class="bg-dark text-light">1:04</td>
                                <td class="bg-dark text-light">1:04</td>
                                <td class="bg-dark text-light">1:04</td>
                                <td class="bg-dark text-light">0</td>
                                <td class="bg-dark text-light"></td>
                                <td class="bg-dark text-light"></td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <>"No vehicle Trip Found"</>
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleStopList;
