import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import {
  actionUpdateManagementVehicle,
  actionUpdateTachographVehicle,
} from "../../services/VehicleService";
import { getTachnoDriverList } from "../../services/TachnographService";

import Select from "react-select";

import { useNavigate } from "react-router-dom";

const tableLengthList = configDb.data.dataTableLength;

const Tachograph = ({ setActiveTab, vehicleDetails, setAddVehicleStatus }) => {
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);

  const VehicleDataID = localStorage.getItem("vehicleId");

  const navigate = useNavigate();

  const [vehicleData, setVehicleData] = useState({
    techograph_type: "",
    tachograph_uses_one_minute: 0,
  });

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });

  const [driverTachnoList, setDriverTachnoList] = useState([]);

  useEffect(
    function () {
      getTachnoDriverList()
        .then((response) => {
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setDriverTachnoList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setVehicleData({
      ...vehicleData,
      tachograph_uses_one_minute: newValue,
    });
  };

  useEffect(() => {
    if (vehicleDetails) {
      setVehicleData({
        techograph_type: vehicleDetails?.tachograph?.tachograph_type,
        tachograph_uses_one_minute:
          vehicleDetails?.tachograph?.tachograph_uses_one_minute,
      });
    }
  }, [vehicleDetails]);

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let data = {
      vehicle_id: VehicleDataID,
      tachograph_type: vehicleData?.techograph_type,
      tachograph_uses_one_minute: vehicleData?.tachograph_uses_one_minute,
    };

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      data.companyId = vehicleData.companyId;
    }

    let updateData = {
      vehicle_id: vehicleDetails?.id,
      uuid: vehicleDetails?.tachograph?.uuid,
      tachograph_type: vehicleData?.techograph_type,
      tachograph_uses_one_minute: vehicleData?.tachograph_uses_one_minute,
    };

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      updateData.companyId = vehicleData.companyId;
    }

    if (vehicleDetails?.tachograph?.uuid) {
      modifymanagament(actionUpdateTachographVehicle, updateData);
    } else {
      modifymanagament(actionUpdateTachographVehicle, data);
    }
  };

  const modifymanagament = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setVehicleData({
          techograph_type: "",
          tachograph_uses_one_minute: 0,
        });
        // navigate("/vehicle");
        setAddVehicleStatus(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleVehicleForm}>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Tachograph type"}
                    </Label>
                    <div className="input-div">
                      <Select
                        options={driverTachnoList}
                        value={driverTachnoList?.find(
                          (option) =>
                            option.value === vehicleData.techograph_type
                        )}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            setVehicleData((prevData) => ({
                              ...prevData,
                              techograph_type: selectedOption.value,
                            }));
                          }
                        }}
                        placeholder="Select Tachograph"
                      />
                    </div>
                  </div>

                  <div className="col-md-4" style={{ marginTop: "10px" }}>
                    <Label className="col-form-label">Tachograph type</Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="tachographTypeCheckbox"
                          checked={vehicleData.tachograph_uses_one_minute}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="tachographTypeCheckbox"
                        >
                          Tachograph Type
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tachograph;
