import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import {
  actionClassVehicle,
  actionDelVehicle,
  actionUpdateVehicle,
  getVehicleList,
} from "../../services/VehicleService";
import { actionCompanyList } from "../../services/Common";
import Select from "react-select";
import { getDriverList } from "../../services/TripService";
import TimePicker from "react-time-picker";
import {
  actionAddNotification,
  actionDelNotification,
  getAllVehicleTaskList,
  getAllgeoFenseList,
  getNotificationList,
} from "../../services/AlarmNotificationService";
import validator from "validator";
import AddNotification from "./AddNotification";

const tableLengthList = configDb.data.dataTableLength;

const Notification = () => {
  const Type = [
    { value: 1, label: "OverSpeed", name: "over_speed" },
    { value: 2, label: "LowerSpeed", name: "low_speed" },
    { value: 3, label: "Maintainance Task", name: "maintainance_task" },
    { value: 4, label: "Geo Fense IN", name: "in" },
    { value: 5, label: "Temperature", name: "temperature" },
    { value: 6, label: "Geo Fense OUT", name: "out" },
    // { value: 7, label: "Fuel" },
  ];

  const data = localStorage.getItem(ConfigDB.data.login_data);

  const allPermissionsList = useSelector((x) => x.permission.value);
  const [vehicleList, setVehicleList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [modifyDetailsModal, setModifyDetailsModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delSubscription, setDelSubscription] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [demo, setDemo] = useState({});
  const [driver, setDriver] = useState("");
  const [notificationData, setNotificationData] = useState({
    uuid: "",
    type_name: "",
    temperature: "",
    lower_speed_limit: "",
    over_speed_limit: "",
    maintainance_task_id: "",
    email: "",
    geo_fense_id: "",
    vehicle_id: "",
  });

  const [vehicleTaskList, setVehicleTaskList] = useState([]);
  const [geofenseList, setgeoFenseList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState("");
  const [NotificationStatus, setNotificationStatus] = useState(false);
  const [NotificationDataAdd, setNotificationDataAdd] = useState({});

  const handleNotificationStatus = (e, item) => {
    setNotificationStatus(true);
    setNotificationDataAdd(item);
  };

  let errorsObj = {
    email: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    vehicleId: vehicle.vehicleId,
    // type_name: notificationData.type_name,
  });

  useEffect(() => {
    let data = params;
    getVehicleList(data)
      .then((response) => {
        response = response.data;
        let list = [];

        list.push({ value: "All", label: "All" });

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].vehicleName,
          });
        }

        setVehicleList(list);
      })
      .catch((err) => {
        console.error("Error fetching vehicle list:", err);
      });
  }, [params]);

  useEffect(
    function () {
      let data = params;
      getAllVehicleTaskList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].task_name,
            });
          }

          setVehicleTaskList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getAllgeoFenseList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].radius_zonename,
            });
          }

          setgeoFenseList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getNotificationList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setNotificationList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };

    const getValue = (e) => (e ? (e.target ? e.target.value : e.value) : null);

    if (type === "length") {
      paramsObj.limit = getValue(e);
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = getValue(e);
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "vehicle") {
      paramsObj.page = 1;
      paramsObj[type === "vehicle" ? "vehicleId" : ""] = getValue(e);
    }

    setParams(paramsObj);
  };
  const onAddSubscription = (e) => {
    e.preventDefault();
    setNotificationData({
      vehicleName: "",
      modelYear: "",
      vehicleClass: "",
      vehiclePlate: "",
      maintainanceKm: "",
      deviceImei: "",
      driverId: "",
      speed: "",
      total_km_after_maintanance: "",
    });
    setModifyModal(true);
  };

  const onDetailsShowSubscription = (e, item) => {
    e.preventDefault();
    setNotificationData({
      uuid: item.uuid,
      vehicleName: item.vehicleName,
      modelYear: item.modelYear,
      vehicleClass: item.vehicleClass,
      companyId: item.companyId,
      vehiclePlate: item.vehiclePlate,
      maintainanceKm: item.maintainanceKm,
      deviceImei: item.device_imei,
      driverId: item.driverId,
      speed: item.speed,
      total_km_after_maintanance: item.total_km_after_maintanance,
    });
    setDemo(item);
    setDriver(item.driverId);
    setModifyDetailsModal(true);
  };

  const onDetailsModalClose = (e) => {
    setModifyDetailsModal(false);
  };

  const onDeleteNotification = (e, item) => {
    e.preventDefault();
    setDelSubscription(item.uuid);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelSubscription("");
    setDelModal(false);
  };

  const onDestroyNotification = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delSubscription };
    actionDelNotification(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelSubscription("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleAddNotificationForm = (e) => {
    e.preventDefault();
    // let errorObj = { ...errorsObj };
    // let error = false;

    // if (!validator.isEmail(email)) {
    //   errorObj.email = "Please enter valid email address";
    //   error = true;
    // }

    // setErrors(errorObj);
    // if (error) return;

    let data = {
      type_name: notificationData.type_name,
      temperature: notificationData.temperature,
      lower_speed_limit: notificationData.lower_speed_limit,
      over_speed_limit: notificationData.over_speed_limit,
      maintainance_task_id: notificationData.maintainance_task_id,
      email: notificationData.email,
      geo_fense_id: notificationData.geo_fense_id,
      vehicle_id: notificationData.vehicle_id,
      temperature: notificationData.temperature,
    };

    let updatedata = {
      type_name: notificationData.type_name,
      temperature: notificationData.temperature,
      lower_speed_limit: notificationData.lower_speed_limit,
      over_speed_limit: notificationData.over_speed_limit,
      maintainance_task_id: notificationData.maintainance_task_id,
      email: notificationData.email,
      geo_fense_id: notificationData.geo_fense_id,
      vehicle_id: notificationData.vehicle_id,
      temperature: notificationData.temperature,
      uuid: notificationData.uuid,
    };

    if (notificationData.uuid) {
      modifySubscription(actionAddNotification, updatedata);
    } else {
      modifySubscription(actionAddNotification, data);
    }
  };

  const modifySubscription = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setNotificationData({
          type_name: "",
          temperature: "",
          lower_speed_limit: "",
          over_speed_limit: "",
          maintainance_task_id: "",
          email: "",
          geo_fense_id: "",
          vehicle_id: "",
          temperature: "",
        });
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const onModifyModalClose = (e) => {
    setNotificationData({
      type_name: "",
      temperature: "",
      geo_fense_out: "",
      lower_speed_limit: "",
      over_speed_limit: "",
      maintainance_task_id: "",
      email: "",
      geo_fense_id: "",
      vehicle_id: "",
      temperature: "",
    });
    setModifyModal(false);
    setErrors(errorsObj);
  };

  const onEditNotification = (e, item) => {
    let typeName = "";

    switch (item.type_name) {
      case "over_speed":
        typeName = 1;
        break;
      case "low_speed":
        typeName = 2;
        break;
      case "maintainance_task":
        typeName = 3;
        break;
      case "in":
        typeName = 4;
        break;
      case "temperature":
        typeName = 5;
        break;
      case "out":
        typeName = 6;
        break;
      default:
        typeName = "Unknown Type";
        break;
    }

    e.preventDefault();
    setNotificationData({
      uuid: item?.uuid,
      type_name: typeName || item.type_name,
      temperature: item?.temperature,
      lower_speed_limit: item?.lower_speed_limit,
      over_speed_limit: item?.over_speed_limit,
      maintainance_task_id: item?.maintainance_task_id,
      email: item?.email,
      geo_fense_id: item?.geo_fense_id,
      vehicle_id: item.vehicle_id,
    });
    setDemo(item);
    // setDriver(item.driverId);
    setModifyModal(true);
  };

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setNotificationData((prevData) => ({
      ...prevData,
      vehicle_id: selectedValues,
    }));
  };

  const TypeComponents = ({ item }) => {
    let typeName = "";

    switch (item.type_name) {
      case "over_speed":
        typeName = "OverSpeed";
        break;
      case "low_speed":
        typeName = "Low Speed";
        break;
      case "maintainance_task":
        typeName = "Maintainance Task";
        break;
      case "in":
        typeName = "Geo Fense IN";
        break;
      case "temperature":
        typeName = "Temperature";
        break;
      case "out":
        typeName = "Geo Fense OUT";
        break;
      default:
        typeName = "Unknown Type";
        break;
    }

    return <span>{typeName}</span>;
  };

  return (
    <>
      {NotificationStatus ? (
        <AddNotification
          NotificationDataAdd={NotificationDataAdd}
          setNotificationStatus={setNotificationStatus}
        />
      ) : (
        <>
          <div className="inner-header py-3">
            <div className="left-block">
              <h5>Alarms</h5>
            </div>

            <div className="right-block w-auto">
              {allPermissionsList.findIndex((e) => e.name == "modify_vehicle") >
                -1 && (
                <Button
                  type={`button`}
                  onClick={handleNotificationStatus}
                  className={`add-btn btn  float-right`}
                >
                  {`Add New Alert`}
                  <i className="plus-circle">
                    <img src={plusIcon} alt="" className="plus-icons" />
                  </i>
                </Button>
              )}
            </div>
          </div>

          <div className="container-event">
            <div className="page-header-row page-header-task-vehical">
              <div className="row">
                <div className="col">
                  <div className="row d-flex justify-content-between company-section">
                    <div className="col-xl-3">
                      <div className="selected-block selected-mobile">
                        <p>Show</p>
                        <select
                          className="form-select"
                          aria-label="Events Only"
                          defaultValue={params.limit}
                          onChange={(e) => handleParams(e, "length")}
                        >
                          {tableLengthList &&
                            tableLengthList.map((item, i) => (
                              <option value={item} key={i}>
                                {item}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-xl-3">
                      <div className="selected-block selected-mobile">
                        <p>Vehicle</p>
                        <div>
                          <Select
                            options={vehicleList}
                            value={vehicleList?.find(
                              (option) => option.value === params.vehicleId
                            )}
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                setVehicle((prevData) => ({
                                  ...prevData,
                                  vehicleId: selectedOption.value,
                                }));
                                handleParams(selectedOption, "vehicle");
                              } else {
                                setVehicle((prevData) => ({
                                  ...prevData,
                                  vehicleId: null,
                                }));
                                handleParams(null, "vehicle");
                              }
                            }}
                            placeholder="Select Vehicle"
                            isClearable={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3"></div>
                    {/* <div className="col-xl-2">
                  <div className="selected-block selected-mobile">
                    <p>Type</p>
                    <div style={{ width: "100%" }}>
                      <Select
                        options={Type}
                        value={Type?.find(
                          (option) =>
                            option.name === notificationData.type_name
                        )}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            setNotificationData((prevData) => ({
                              ...prevData,
                              type_name: selectedOption.name,
                            }));
                            handleParams(selectedOption, "type_name");
                          } else {
                            setNotificationData((prevData) => ({
                              ...prevData,
                              type_name: null,
                            }));
                            handleParams(null, "type_name");
                          }
                        }}
                        placeholder={"Select Alert Type"}
                        isClearable={true}
                      />
                    </div>
                  </div>
                </div> */}
                    <div className="col-xl-3">
                      <div className="selected-block selected-mobile">
                        <p> Search</p>
                        <div className="search-bar">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page-table-row">
              <table
                className={`table table-striped ${isLoading ? "loading" : ""}`}
                width="100%"
              >
                <thead>
                  <tr>
                    <th scope={`col`}>{`Type`}</th>
                    <th scope={`col`}>{`Value`}</th>
                    <th scope={`col`}>{`Vehicle`}</th>
                    <th scope={`col`}>{`Email`}</th>
                    <th scope={`col`}>{`Action`}</th>
                  </tr>
                </thead>
                <tbody>
                  {notificationList &&
                    notificationList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td
                            onClick={(e) => onDetailsShowSubscription(e, item)}
                            style={{ cursor: "pointer" }}
                          >
                            <TypeComponents item={item} />
                          </td>
                          <td
                            onClick={(e) => onDetailsShowSubscription(e, item)}
                            style={{ cursor: "pointer" }}
                          >
                            {item.type_name == "over_speed"
                              ? item.over_speed_limit
                              : item.type_name == "low_speed"
                              ? item.lower_speed_limit
                              : item.type_name == "maintainance_task"
                              ? item.task_name
                              : item.type_name == "in"
                              ? item.radius_zonename
                              : item.type_name == "out"
                              ? item.radius_zonename
                              : item.type_name == "temperature"
                              ? item.temperature
                              : null}
                          </td>
                          <td
                            onClick={(e) => onDetailsShowSubscription(e, item)}
                            style={{ cursor: "pointer" }}
                          >
                            {item.vehicle_names}
                          </td>
                          <td
                            onClick={(e) => onDetailsShowSubscription(e, item)}
                            style={{ cursor: "pointer" }}
                          >
                            {item.email}
                          </td>

                          <td>
                            <button
                              type={`button`}
                              onClick={(e) => handleNotificationStatus(e, item)}
                              className={`btn btn-info add-btn-circle me-2`}
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </button>
                            <button
                              type={`button`}
                              onClick={(e) => onDeleteNotification(e, item)}
                              className={`btn btn-circle btn-danger add-btn-circle me-2`}
                            >
                              <i className="bi bi-trash-fill"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}

                  {notificationList && notificationList?.data?.length === 0 && (
                    <tr>
                      <td
                        colSpan={
                          allPermissionsList.findIndex(
                            (e) => e.name == "modify_vehicle"
                          ) > -1 ||
                          allPermissionsList.findIndex(
                            (e) => e.name == "delete_vehicle"
                          ) > -1
                            ? 7
                            : 7
                        }
                        className={`text-center`}
                      >{`Record Not Found`}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={`float-right`}>
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </>
      )}

      <Modal isOpen={modifyModal}>
        <ModalHeader>Alert</ModalHeader>
        <Form method={`post`} onSubmit={handleAddNotificationForm}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">
                {"Alert Type"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="mb-3">
                <Select
                  options={Type}
                  value={Type.find(
                    (option) => option.value === notificationData.type_name
                  )}
                  onChange={(selectedOption) =>
                    setNotificationData((prevData) => ({
                      ...prevData,
                      type_name: selectedOption.name,
                    }))
                  }
                  placeholder={"Select Alert Type"}
                />
                {errors.type_name && (
                  <span className="input-error">{errors.type_name}</span>
                )}
              </div>
            </FormGroup>
            {(notificationData.type_name === "over_speed" ||
              notificationData.type_name === 1) && (
              <FormGroup>
                <Label className="col-form-label">
                  {"OverSpeed Limit"}
                  <span className="text-danger ml-1">*</span>
                </Label>
                <div className="input-div">
                  <input
                    className="form-control"
                    type="text"
                    value={notificationData.over_speed_limit}
                    onChange={(e) => {
                      setNotificationData({
                        ...notificationData,
                        over_speed_limit: e.target.value,
                      });
                    }}
                    name="over_speed_limit"
                    maxLength={50}
                    placeholder="OverSpeed Limit"
                  />
                  {errors.over_speed_limit && (
                    <span className="input-error">
                      {errors.over_speed_limit}
                    </span>
                  )}
                </div>
              </FormGroup>
            )}

            {(notificationData.type_name === "low_speed" ||
              notificationData.type_name === 2) && (
              <FormGroup>
                <Label className="col-form-label">
                  {"Low Speed Limit"}
                  <span className={`text-danger ml-1`}>*</span>
                </Label>
                <div className="input-div">
                  <input
                    className="form-control"
                    type="text"
                    value={notificationData.lower_speed_limit}
                    onChange={(e) => {
                      setNotificationData({
                        ...notificationData,
                        lower_speed_limit: e.target.value,
                      });
                    }}
                    name={`lower_speed_limit`}
                    maxLength={50}
                    placeholder="Low Speed Limit"
                  />
                  {errors.lower_speed_limit && (
                    <span className="input-error">
                      {errors.lower_speed_limit}
                    </span>
                  )}
                </div>
              </FormGroup>
            )}

            {(notificationData.type_name === "maintainance_task" ||
              notificationData.type_name === 3) && (
              <FormGroup>
                <Label className="col-form-label">
                  {"Maintainance Task"}
                  <span className={`text-danger ml-1`}>*</span>
                </Label>
                <div className="mb-3">
                  <Select
                    options={vehicleTaskList}
                    value={vehicleTaskList.find(
                      (option) =>
                        option.value === notificationData.maintainance_task_id
                    )}
                    onChange={(selectedOption) =>
                      setNotificationData((prevData) => ({
                        ...prevData,
                        maintainance_task_id: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Maintainance Task"}
                  />
                  {/* {errors.type && (
      <span className="input-error">{errors.type}</span>
    )} */}
                </div>
              </FormGroup>
            )}

            {(notificationData.type_name === "in" ||
              notificationData.type_name === 4) && (
              <>
                <FormGroup>
                  <Label className="col-form-label">
                    {"Zone"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="mb-3">
                    <Select
                      options={geofenseList}
                      value={geofenseList.find(
                        (option) =>
                          option.value === notificationData.geo_fense_id
                      )}
                      onChange={(selectedOption) =>
                        setNotificationData((prevData) => ({
                          ...prevData,
                          geo_fense_id: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Zone"}
                    />
                    {/* {errors.type && (
       <span className="input-error">{errors.type}</span>
     )} */}
                  </div>
                </FormGroup>
              </>
            )}

            {(notificationData.type_name === "out" ||
              notificationData.type_name === 6) && (
              <>
                <FormGroup>
                  <Label className="col-form-label">
                    {"Zone"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="mb-3">
                    <Select
                      options={geofenseList}
                      value={geofenseList.find(
                        (option) =>
                          option.value === notificationData.geo_fense_id
                      )}
                      onChange={(selectedOption) =>
                        setNotificationData((prevData) => ({
                          ...prevData,
                          geo_fense_id: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Zone"}
                    />
                  </div>
                </FormGroup>
              </>
            )}

            {(notificationData.type_name === "temperature" ||
              notificationData.type_name === 5) && (
              <FormGroup>
                <Label className="col-form-label">
                  {"Temperature"}
                  <span className={`text-danger ml-1`}>*</span>
                </Label>
                <div className="input-div">
                  <input
                    className="form-control"
                    type="text"
                    value={notificationData.temperature}
                    onChange={(e) => {
                      setNotificationData({
                        ...notificationData,
                        temperature: e.target.value,
                      });
                    }}
                    name={`temperature`}
                    maxLength={50}
                    placeholder="Temperature"
                  />
                  {errors.temperature && (
                    <span className="input-error">{errors.temperature}</span>
                  )}
                </div>
              </FormGroup>
            )}

            {(notificationData.type_name === "over_speed" ||
              notificationData.type_name === "low_speed" ||
              notificationData.type_name === "maintainance_task" ||
              notificationData.type_name === "in" ||
              notificationData.type_name === "temperature" ||
              notificationData.type_name === "out" ||
              notificationData.type_name === 1 ||
              notificationData.type_name === 2 ||
              notificationData.type_name === 3 ||
              notificationData.type_name === 4 ||
              notificationData.type_name === 5 ||
              notificationData.type_name === 6) && (
              <>
                <FormGroup>
                  <Label className="col-form-label">
                    {"Email"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={notificationData.email}
                      onChange={(e) => {
                        setNotificationData({
                          ...notificationData,
                          email: e.target.value,
                        });
                      }}
                      name={`email`}
                      maxLength={50}
                      placeholder="Email"
                    />
                    {errors.email && (
                      <span className="input-error">{errors.email}</span>
                    )}
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label className="col-form-label">
                    {"Vehicle"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <Select
                      options={vehicleList}
                      isMulti
                      value={vehicleList.filter(
                        (option) =>
                          Array.isArray(notificationData.vehicle_id) &&
                          notificationData.vehicle_id.includes(option.value)
                      )}
                      onChange={handleChange}
                      placeholder={"Select Vehicle"}
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                    />
                  </div>
                </FormGroup>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this vehicle?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square bg-danger border-0`}
              onClick={onDestroyNotification}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Notification;
