import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import configDb from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import Select from "react-select";

import { useNavigate } from "react-router-dom";
import {
  createAccountDriverApp,
  createAccountRoute,
} from "../../services/ManagementService";

const tableLengthList = configDb.data.dataTableLength;

const DriverType = [
  {
    value: "1",
    label: "Google maps",
  },
  { value: "2", label: " Sygic Truck & Caravan" },
];

const vehicleList = [
  {
    id: 1,
    name: "Trips",
  },
  { id: 2, name: "Trip Details" },
  {
    id: 3,
    name: "Driving Behavior",
  },
  {
    id: 4,
    name: "Driving Behavior dashboard",
  },
  {
    id: 5,
    name: "Privacy Button",
  },
  {
    id: 6,
    name: "Create a place",
  },
  {
    id: 7,
    name: "Driving Time",
  },
  {
    id: 8,
    name: "Alert",
  },
  {
    id: 9,
    name: "Conversation",
  },
  {
    id: 10,
    name: "Change Vehicle",
  },
];

const DriverApp = ({ setActiveTab, AccountDetails }) => {
  const navigate = useNavigate();

  const [routeData, setRouteData] = useState({
    activer_driver_app: 1,
    navigation_system: 0,
    change_confirmation: "",
    set_for_all_drivers: 0,
  });
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [modifyModel, setModifyModel] = useState(false);

  const [modifyModelCheck, setModifyModelCheck] = useState(false);

  //   const handleAllDriverCheck = () => {
  //     setModifyModelCheck(true);
  //   };

  const handleAllDriver = () => {
    setModifyModel(true);
  };

  const handleAllDriverClose = () => {
    setModifyModel(false);
  };

  // const handleCheckboxChanges = (e, item) => {
  //   const isChecked = e.target.checked;
  //   setSelectedVehicles((prevSelected) => {
  //     const newSelection = isChecked
  //       ? [...prevSelected, item.id]
  //       : prevSelected?.filter((id) => id !== item.id);
  //     return newSelection;
  //   });
  // };

  const handleCheckboxChanges = (e, item) => {
    const isChecked = e.target.checked;
    setSelectedVehicles((prevSelected) => {
      const currentSelection = Array.isArray(prevSelected) ? prevSelected : [];
  
      const newSelection = isChecked
        ? [...currentSelection, item.id]
        : currentSelection.filter((id) => id !== item.id);
  
      return newSelection;
    });
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newSelection = isChecked ? vehicleList?.map((item) => item.id) : [];
    setSelectedVehicles(newSelection);
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;

    if (newValue === 0) {
      setModifyModelCheck(true);
    } else {
      setRouteData({
        ...routeData,
        activer_driver_app: newValue,
      });
    }
  };

  const handleAllDriverCheckClose = (confirm) => {
    if (confirm) {
      setRouteData({ ...routeData, activer_driver_app: 0 });
    }
    setModifyModelCheck(false);
  };

  const handleCheckboxChangeConfirmation = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setRouteData({
      ...routeData,
      change_confirmation: newValue,
    });
  };

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });

  useEffect(() => {
    if (AccountDetails) {
      setRouteData({
        activer_driver_app: AccountDetails?.driver_app?.activate_driver_app,
        navigation_system: AccountDetails?.driver_app?.navigation_system,
        change_confirmation:
          AccountDetails?.driver_app?.change_confirmation_dialogs,

        set_for_all_drivers: AccountDetails?.driver_app?.set_for_all_drivers,
      });
      setSelectedVehicles(AccountDetails?.driver_app?.app_permission);
    }
  }, [AccountDetails]);

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let data = {
      activer_driver_app: routeData?.activer_driver_app,
      navigation_system: routeData?.navigation_system,
      change_confirmation: routeData?.change_confirmation,
      app_permission: selectedVehicles,
      set_for_all_drivers: routeData?.set_for_all_drivers,
    };

    let updateData = {
      uuid: AccountDetails?.driver_app?.uuid,
      activer_driver_app: routeData?.activer_driver_app,
      navigation_system: routeData?.navigation_system,
      change_confirmation: routeData?.change_confirmation,
      app_permission: selectedVehicles,
      set_for_all_drivers: routeData?.set_for_all_drivers,
    };
    if (AccountDetails?.driver_app?.uuid) {
      modifymanagament(createAccountDriverApp, updateData);
    } else {
      modifymanagament(createAccountDriverApp, data);
    }
  };

  const modifymanagament = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("driver");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const selctedroute = DriverType?.find(
    (option) => option.value === JSON.stringify(routeData.navigation_system)
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <h5>Setting</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Activate Driver App"}
                      </Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="activer_driver_app"
                            checked={routeData.activer_driver_app === 1}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="activer_driver_app"
                          >
                            Activate Driver App{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              {routeData.activer_driver_app === 1 && (
                <>
                  <h5>Drivers Standard Configurations and permissions</h5>
                  <div
                    className="allDrivers"
                  >
                    <Button
                      style={{ backgroundColor: "#45abe3" }}
                      onClick={handleAllDriver}
                      className={`btn-square`}
                    >
                      Set To all Drivers
                    </Button>
                  </div>
                  <FormGroup>
                    <div className="add-vehicle-rw">
                      <div className="row">
                        <div className="col-md-4">
                          <Label className="col-form-label">
                            {"Navigation system"}
                          </Label>
                          <div className="mb-3">
                            <Select
                              options={DriverType}
                              value={selctedroute}
                              onChange={(selectedOption) =>
                                setRouteData((prevData) => ({
                                  ...prevData,
                                  navigation_system: selectedOption.value,
                                }))
                              }
                              placeholder={"Select Navigation system"}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <Label className="col-form-label">
                            {"Change Confirmation dialogs"}
                          </Label>
                          <div className="input-div">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="change_confirmation"
                                checked={routeData.change_confirmation}
                                onChange={handleCheckboxChangeConfirmation}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="change_confirmation"
                              >
                                Change Confirmation dialogs{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container-event">
                        <div className="page-table-row vehicle-td">
                          <table className={`table table-striped`} width="100%">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <input
                                    type="checkbox"
                                    onChange={handleSelectAll}
                                    checked={
                                      vehicleList?.length > 0 &&
                                      selectedVehicles?.length ===
                                        vehicleList?.length
                                    }
                                  />
                                </th>
                                <th scope="col">Driver Name</th>
                              </tr>
                            </thead>
                            {vehicleList?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedVehicles?.includes(item?.id)}
                                    onChange={(e) =>
                                      handleCheckboxChanges(e, item)
                                    }
                                  />
                                </td>
                                <td>{item.name || "-"}</td>
                              </tr>
                            ))}
                          </table>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <Modal isOpen={modifyModel}>
        <ModalHeader>{`SET TO ALL DRIVERS`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Do you want to set all your drivers permissions to these default permissions?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            outline
            onClick={handleAllDriverClose}
            className={`btn-square`}
          >{`No`}</Button>
          <Button
            color="secondary"
            type={`button`}
            className={`btn-square btn-info`}
            //   onClick={onDestroyRole}
          >{`Yes`}</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modifyModelCheck}>
        <ModalHeader>{`CANCEL DRIVER APP FROM YOUR ACCOUNT`}</ModalHeader>
        <ModalBody>
          <p className={`mb-0`}>
            {`Proceeding with this action will prevent all your drivers from logging into their Driver App. Do you want to continue?`}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="button"
            outline
            onClick={handleAllDriverCheckClose}
            className={`btn-square`}
          >
            {`No`}
          </Button>
          <Button
            color="secondary"
            type="button"
            className={`btn-square`}
            // onClick={() => handleModalClose(true)}
          >
            {`Yes`}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DriverApp;
