import React, { useState } from "react";
import WeekTachnoDetails from "./WeekTachnoDetails";
import DailyTachnoDetails from "./DailyTachnoDetails";

const TachnoGraphAnaysisDetails = ({ TachnoDriverDetailsList }) => {
  const [activeTab, setActiveTab] = useState("weekly");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Administration</h5>
        </div>
      </div>

      <div className="leaflet-control-management managementui-tab dashboardTachno">
        <div className="tabs tachnoTab">
          <div className="tachnoTab-nav">

            <nav className="tab-nav">
              <ul className="tabs-management">
                <li
                    className={`nav-link ${activeTab === "weekly" ? "active" : ""}`}
                    role="tab"
                    onClick={(e) => {
                      e.preventDefault();
                      handleTabClick("weekly");
                    }}
                >
                  <span data-href="#tab-1">Weekly View</span>
                </li>

                <li
                    className={`nav-link ${activeTab === "daily" ? "active" : ""
                        }`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleTabClick("daily");
                    }}
                >
                  <span data-href="#tab-2">Daily View</span>
                </li>
              </ul>

            </nav>
            <div className="tachnographBtn me-4">
              <button className="btn btn-primary me-md-2 tachnographBtnbg" type="button">New Search</button>
              <button className="btn btn-primary ms-2 tachnographBtnbg" type="button">Generate Report</button>
            </div>
          </div>
          <div className="dashboard-block tab-content tachnoDashboard" id="nav-tabContent">
            <div
              className={`tab-pane fade ${activeTab === "weekly" ? "show active" : ""
                }`}
              id="tab-1"
            >
              <WeekTachnoDetails
                TachnoDriverDetailsList={TachnoDriverDetailsList}
              />
            </div>

            <div
              className={`tab-pane fade ${activeTab === "daily" ? "show active" : ""
                }`}
              id="tab-1"
            >
              <DailyTachnoDetails
                TachnoDriverDetailsList={TachnoDriverDetailsList}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TachnoGraphAnaysisDetails;
