import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import {
  actionAlarmReports,
  getRouteReportList,
} from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import moment from "moment";
import * as XLSX from "xlsx";

const RouteExecutionList = () => {
  const [alarmsDetails, setAlarmsDetails] = useState("");
  const [alarmsDetailsList, setAlarmsDetailsList] = useState([]);
  const storedData = localStorage.getItem("routeReportData");
  const parsedData = JSON.parse(storedData);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const alarms = event.data;
      setAlarmsDetails(alarms);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    // if (alarmsDetails) {
    let data = {
      vehicleId: parsedData?.vehicleId,
      startDate: parsedData?.startDate,
      post_execution_detection: parsedData?.post_execution_detection,
    };
    getRouteReportList(data)
      .then((response) => {
        setAlarmsDetailsList(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
    // }
  }, []);

  const exportToExcel = () => {
    if (!alarmsDetailsList || alarmsDetailsList.length === 0) {
      toast.error("No data to export!");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(alarmsDetailsList);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alarms");

    XLSX.writeFile(workbook, "round_execution.xlsx");
  };

  return (
    <div>
      <>
        <div className="tablePage">
          <div className="container-fluid">
            <div className="tablePageMain mt-2">
              <div className>
                <div className="logoimg">
                  <img src={Logo} alt="logoimg" />
                </div>
              </div>
              <div className>
                <div className="Content">
                  <div>
                    <h3>Route Execution</h3>
                    <h5 style={{ cursor: "pointer" }} onClick={exportToExcel}>
                      Export to Excel
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            {alarmsDetailsList?.map((item) => {
              const timePart = moment(item?.estimated_date)
                .format("DD-MM-YYYY HH:mm:ss")
                .split(" ")[1];

              const timeToMinutes = (hours, minutes, seconds) => {
                const totalMinutes = hours * 60 + minutes + seconds / 60;
                return totalMinutes;
              };

              const [hours, minutes, seconds] = timePart.split(":").map(Number);

              const totalMinutes = timeToMinutes(hours, minutes, seconds);

              const diffrence = item.total_duration - totalMinutes;
              return (
                <>
                  <div className="custom-table mt-4">
                    <div class="tablePage">
                      <div class="container-fluid">
                        <div class="tablePageMain mt-2 table-title">
                          <div>
                            <div class="logoimg">
                              <h5>Vehicle: {item.vehicle_name}</h5>
                            </div>
                          </div>
                          <div class="">
                            <div class="Content">
                              <div class="pe-3">
                                <h5>Driver: {item.driver_name}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class=" container-fluid">
                      <div class="table-titled">
                        <div class="container-fluid text-left">
                          <div class="routes">
                            <div class="route text-light">
                              Route: {item.trip_name}
                            </div>
                            <div class="route text-light">
                              Number of faults: -
                            </div>
                            <div class="route text-light">Faults: C</div>
                          </div>
                          <div class="routes mt-2">
                            <div class="route text-light">
                              Start place: {item?.source_place}
                            </div>
                            <div class="route text-light">
                              End place: {item?.destination_place}
                            </div>
                          </div>
                        </div>
                      </div>
                      {alarmsDetailsList?.length > 0 ? (
                        <div class="custom-table mt-4 table-responsive">
                          <table class="table table-striped table-hover w-50">
                            <thead class="table-light">
                              <tr class="text-center">
                                <th scope="col">Indicator</th>
                                <th scope="col">Planned/Estimated</th>
                                <th scope="col">Executed</th>
                                <th scope="col">Difference</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Departure time</td>
                                <td>
                                  {moment(item?.estimated_date).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                  )}
                                </td>
                                <td>{item?.total_duration}</td>
                                <td>{diffrence}</td>
                              </tr>
                              <tr>
                                <td>Total mileage (km)</td>
                                <td>{item.total_distance}</td>
                                <td>0.0</td>
                                <td>{item.total_distance}</td>
                              </tr>
                              <tr>
                                <td>Total cost</td>
                                <td>{item.price}</td>
                                <td>0.00</td>
                                <td>0.00</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p>No Route Execution details available.</p>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
      {/* ) : (
        <p>No Route Execution details available.</p>
      )} */}
    </div>
  );
};

export default RouteExecutionList;
