import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import configDb from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import {
  actionDelVehicleClass,
  actionUpdateVehicleClass,
  getVehicleClassList,
} from "../../services/VehicleClassService";
import {
  actionAddUpdatemaintainanceTask,
  actionAddUpdatemaintainanceparm,
  actionDelMaintainanceTask,
  actionDelMaintainanceparm,
  getMaintainanceTaskList,
  getMaintainanceparmList,
} from "../../services/Maintanance";
import Select from "react-select";

const tableLengthList = configDb.data.dataTableLength;

const MaintainanceTask = (props) => {
  const auth = useSelector((x) => x.auth.value);
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [vehicleClassList, setVehicleClassList] = useState([]);
  const [maintainanceparmList, setMaintainanceparmList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delVehicleClass, setDelVehicleClass] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [vehicleClassData, setVehicleClassData] = useState({
    uuid: "",
    task_name: "",
    maintainance_km: "",
    sub_task: "",
    isCommon: false,
  });

  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let errorsObj = { task_name: "", maintainance_km: "", sub_task: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getMaintainanceTaskList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setVehicleClassList(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getMaintainanceparmList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }
          setMaintainanceparmList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };
  const onDeleteVehicleClass = (e, item) => {
    e.preventDefault();
    setDelVehicleClass(item.uuid);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelVehicleClass("");
    setDelModal(false);
  };

  const onDestroyVehicleClass = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delVehicleClass };
    actionDelMaintainanceTask(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelVehicleClass("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };
  const onAddVehicleClass = (e) => {
    e.preventDefault();
    setVehicleClassData({ uuid: "", name: "" });
    setModifyModal(true);
  };
  const onEditVehicleClass = (e, item) => {
    e.preventDefault();
    setVehicleClassData({
      uuid: item.uuid,
      sub_task: item.sub_task,
      task_name: item.task_name,
      maintainance_km: item.maintainance_km,
    });
    setModifyModal(true);
  };
  const onModifyModalClose = (e) => {
    setVehicleClassData({ uuid: "", name: "" });
    setModifyModal(false);
    setErrors(errorsObj);
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setVehicleClassData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleVehicleClassForm = (e) => {
    e.preventDefault();
    let dataParams = { ...params };

    let error = false;
    let errorObj = { ...errorsObj };
    if (!vehicleClassData.task_name) {
      errorObj.task_name = `Required Task Name`;
      error = true;
    }

    if (!vehicleClassData.maintainance_km) {
      errorObj.maintainance_km = `Required Maintainance km`;
      error = true;
    }

    if (!vehicleClassData.sub_task) {
      errorObj.sub_task = `Required Maintainance Parameter`;
      error = true;
    }

    setErrors(errorObj);

    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      uuid: "",
      name: vehicleClassData.task_name,
      maintainance_km: vehicleClassData.maintainance_km,
      sub_task: vehicleClassData.sub_task,
    };

    if (vehicleClassData.uuid) {
      data["uuid"] = vehicleClassData.uuid;
    }

    actionAddUpdatemaintainanceTask(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setVehicleClassData({ uuid: "", name: "", isCommon: false });
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Maintainance Task</h5>
        </div>

        <div className="right-block w-auto">
          {allPermissionsList.findIndex(
            (e) => e.name == "modify_vehicle_class"
          ) > -1 && (
            <Button
              type={`button`}
              onClick={onAddVehicleClass}
              className={`add-btn btn float-right`}
            >
              {`Add Maintainance Task`}
              <i className="plus-circle">
                <img src={plusIcon} alt="" className="plus-icons" />
              </i>
            </Button>
          )}
        </div>
      </div>

      <div className="container-event">
        <div className="page-header-row">
          <div className="row">
            <div className="col">
              <div className="row d-flex justify-content-between company-section">
                <div className="col-xl-2">
                  <div className="selected-block">
                    <p>Show</p>
                    <select
                      className="form-select"
                      aria-label="Events Only"
                      defaultValue={params.limit}
                      onChange={(e) => handleParams(e, "length")}
                    >
                      {tableLengthList &&
                        tableLengthList.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="search-bar">
                    <i className="bi bi-search"></i>
                    <input
                      type="text"
                      className="form-control"
                      value={params.search}
                      onChange={(e) => handleParams(e, "search")}
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`} width="5%">
                  #
                </th>
                <th scope={`col`}>{`Task Name`}</th>
                <th scope={`col`}>{`Maintainance Km`}</th>
                <th scope={`col`}>{`Sub Task`}</th>
                <th scope={`col`} width="20%">{`Action`}</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {vehicleClassList &&
                    vehicleClassList?.data?.map((item, index) => (
                      <tr>
                        <td key={index}>{index + 1}</td>
                        <td>{item.task_name}</td>
                        <td>{item.maintainance_km}</td>
                        <td>{item.name}</td>
                        <td>
                          <>
                            <Button
                              type={`button`}
                              onClick={(e) => onEditVehicleClass(e, item)}
                              className={`btn btn-info add-btn-circle me-2`}
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </Button>
                          </>

                          <Button
                            type={`button`}
                            onClick={(e) => onDeleteVehicleClass(e, item)}
                            className={`btn btn-danger add-btn-circle btn-square me-2`}
                          >
                            <i className="bi bi-trash-fill"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                </>
              )}

              {vehicleClassList && vehicleClassList?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={
                      allPermissionsList.findIndex(
                        (e) => e.name == "modify_vehicle_class"
                      ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_vehicle_class"
                      ) > -1
                        ? 3
                        : 2
                    }
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle Class`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this MaintananceParameter?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square bg-danger border-0`}
              onClick={onDestroyVehicleClass}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Modal isOpen={modifyModal}>
        <ModalHeader>
          {vehicleClassData.uuid
            ? `Edit Maintanance Parameter`
            : `Create Maintanance Parameter`}
        </ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleClassForm}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">
                {"Task Name"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleClassData.task_name}
                  name={`task_name`}
                  onChange={handleChange}
                  maxLength={50}
                  placeholder="Task Name"
                />
                {errors.task_name && (
                  <span className="input-error">{errors.task_name}</span>
                )}
              </div>

              <Label className="col-form-label">
                {"Maintainance KM"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleClassData.maintainance_km}
                  name={`maintainance_km`}
                  onChange={handleChange}
                  maxLength={50}
                  placeholder="Maintainance km"
                />
                {errors.maintainance_km && (
                  <span className="input-error">{errors.maintainance_km}</span>
                )}
              </div>
              <div>
                <Label className="col-form-label">
                  {"Maintainance Parameter"}
                  <span className={`text-danger ml-1`}>*</span>
                </Label>
              </div>
              <Select
                options={maintainanceparmList}
                value={maintainanceparmList.find(
                  (option) => option.value === vehicleClassData.sub_task
                )}
                onChange={(selectedOption) =>
                  setVehicleClassData((prevData) => ({
                    ...prevData,
                    sub_task: selectedOption.value,
                  }))
                }
                placeholder={"Select Maintainance Parameter"}
              />
              {errors.sub_task && (
                <span className="input-error">{errors.sub_task}</span>
              )}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default MaintainanceTask;
