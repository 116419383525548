import React, { useEffect, useState } from "react";
import GeneralAccount from "./GeneralAccount";
import WorkingPeriodAccount from "./WorkingPeriodAccount";
import ProfileAccount from "./ProfileAccount";
import PermittedDrivingPeriod from "./PermittedDrivingPeriod";
import DrivingCondition from "./DrivingCondition";
import Canbus from "./Canbus";
import AutomationRule from "./AutomationRule";
import CalculationOptions from "./CalculationOptions";
import DireactionAccount from "./DireactionAccount";
import CRM from "./CRM";
import RouteAccount from "./RouteAccount";
import DriverAccount from "./DriverAccount";
import LicenseClass from "./LicenseClass";
import ConfigDB from "../../config";
import { getAccountDetails } from "../../services/ManagementService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import DriverApp from "./DriverApp";

const Account = () => {
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);

  const [activeTab, setActiveTab] = useState("general");
  const [AccountDetails, setAccountDetails] = useState({});

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(function () {
    let data = { company_id: JsonParse.company_id };
    if (JsonParse.company_id > 0) {
    getAccountDetails(data)
      .then((response) => {
        setAccountDetails(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
    }
  }, []);

  return (
    <div>
      <div className="inner-header py-3 d-none">
        <div className="left-block">
          <h5>Management</h5>
        </div>
      </div>

      <div className="leaflet-control-management managementui-tab leaflet-control-var">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "general" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("general");
                }}
              >
                <span data-href="#tab-1">General</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "profile" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("profile");
                }}
              >
                <span data-href="#tab-1">Profile</span>
              </li>
              <li
                className={`nav-link ${
                  activeTab === "workingperiod" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("workingperiod");
                }}
              >
                <span data-href="#tab-1">Working Period</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "permittedWorkingPeriod" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("permittedWorkingPeriod");
                }}
              >
                <span data-href="#tab-1">Permitted Driving Period</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "drivingcondition" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("drivingcondition");
                }}
              >
                <span data-href="#tab-1">Driving Condition</span>
              </li>

              <li
                className={`nav-link ${activeTab === "canbus" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("canbus");
                }}
              >
                <span data-href="#tab-1">CANBUS</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "automationRule" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("automationRule");
                }}
              >
                <span data-href="#tab-1">Automation Rule</span>
              </li>
              <li
                className={`nav-link ${
                  activeTab === "calculationOptions" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("calculationOptions");
                }}
              >
                <span data-href="#tab-1">Calculation Options</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "direction" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("direction");
                }}
              >
                <span data-href="#tab-1">Direction</span>
              </li>

              <li
                className={`nav-link ${activeTab === "crm" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("crm");
                }}
              >
                <span data-href="#tab-1">CRM</span>
              </li>

              <li
                className={`nav-link ${activeTab === "route" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("route");
                }}
              >
                <span data-href="#tab-1">Route</span>
              </li>

              <li
                className={`nav-link ${activeTab === "driver" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("driver");
                }}
              >
                <span data-href="#tab-1">Driver</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "LicenseClass" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("LicenseClass");
                }}
              >
                <span data-href="#tab-1">License Class</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "driverApp" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("driverApp");
                }}
              >
                <span data-href="#tab-1">Driver App</span>
              </li>
            </ul>
          </nav>

          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "general" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <GeneralAccount
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "profile" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <ProfileAccount
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "workingperiod" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <WorkingPeriodAccount
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "permittedWorkingPeriod" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <PermittedDrivingPeriod
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "drivingcondition" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <DrivingCondition
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "canbus" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <Canbus
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "automationRule" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <AutomationRule
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "calculationOptions" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <CalculationOptions
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "direction" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <DireactionAccount
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "crm" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <CRM
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "route" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <RouteAccount
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "driver" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <DriverAccount
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "LicenseClass" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <LicenseClass
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "driverApp" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <DriverApp
                setActiveTab={setActiveTab}
                AccountDetails={AccountDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
