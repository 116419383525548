import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import Select from "react-select";

import { useNavigate } from "react-router-dom";
import { createAccountDrivingCondition } from "../../services/ManagementService";
import { actionClassVehicle } from "../../services/VehicleService";

const tableLengthList = configDb.data.dataTableLength;

const DriverType = [
  {
    value: "1",
    label: "Never",
  },
  { value: "2", label: "CANBus vehicle only" },
  { value: "3", label: "Always" },
];

const numberHalfType = [
  { value: "1", label: "01" },
  { value: "2", label: "02" },
  { value: "3", label: "03" },
  { value: "4", label: "04" },
  { value: "5", label: "05" },
  { value: "6", label: "06" },
  { value: "7", label: "07" },
  { value: "8", label: "08" },
  { value: "9", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "31", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
  { value: "59", label: "59" },
];

const numberHourType = [
  { value: "1", label: "01" },
  { value: "2", label: "02" },
  { value: "3", label: "03" },
  { value: "4", label: "04" },
  { value: "5", label: "05" },
  { value: "6", label: "06" },
  { value: "7", label: "07" },
  { value: "8", label: "08" },
  { value: "9", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
];

const DrivingCondition = ({ setActiveTab, AccountDetails }) => {
  const navigate = useNavigate();

  const [DrivingData, setDrivingData] = useState({
    allow_driving_teams: 0,
    split_trips: "",
    allow_coupling_vehicles: 0,
    use_driving_time_restriction_rules: 0,
    allow_two_consecutive_weekly_rests: 0,
    vehicle_class_id: "",
    night_work_restriction: 0,
    night_period_from_hour: "",
    night_period_from_minutes: "",
    night_period_to_hour: "",
    night_period_to_minutes: "",
    max_dialy_night_work_hour: "",
    max_dialy_night_work_minutes: "",
    max_continuous_driving_hour: "4",
    max_continuous_driving_minutes: "30",
    max_regular_break_hour: "",
    max_regular_break_minutes: "45",
    max_reduced_first_break_hour: "",
    max_reduced_first_break_minutes: "15",
    max_reduced_second_break_hour: "",
    max_reduced_second_break_minutes: "30",
    max_dialy_driving_hour: "9",
    max_dialy_driving_minutes: "",
    max_regular_daily_rest_hour: "11",
    max_regular_daily_rest_minutes: "",
    max_reduced_daily_rest_hour: "9",
    max_reduced_daily_rest_minutes: "",
    max_split_first_daily_rest_hour: "3",
    max_split_first_daily_rest_minutes: "",
    max_split_second_daily_rest_hour: "9",
    max_split_second_daily_rest_minutes: "",
    max_weekly_driving_hour: "56",
    max_weekly_driving_minutes: "",
    max_biweekly_driving_hour: "",
    max_biweekly_driving_minutes: "",
  });

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });
  const [vehicleClasssList, setVehicleClasssList] = useState([]);

  useEffect(
    function () {
      let data = { ...params };
      actionClassVehicle(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicle_class,
            });
          }

          setVehicleClasssList(list);
        })
        .catch((err) => {});
    },

    []
  );

  const handleCheckboxChangeAllowDriving = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      allow_driving_teams: newValue,
    });
  };

  const handleCheckboxCoupling = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      allow_coupling_vehicles: newValue,
    });
  };

  const handleCheckboxUseDrivingTime = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      use_driving_time_restriction_rules: newValue,
    });
  };

  const handleCheckboxUseNightWork = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      night_work_restriction: newValue,
    });
  };

  const handleCheckboxWeekly = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      allow_two_consecutive_weekly_rests: newValue,
    });
  };

  useEffect(() => {
    if (AccountDetails) {
      setDrivingData({
        allow_driving_teams:
          AccountDetails?.permitted_driving_condition?.allow_driving_teams,
        split_trips: AccountDetails?.permitted_driving_condition?.split_trips,
        allow_coupling_vehicles:
          AccountDetails?.permitted_driving_condition?.allow_coupling_vehicles,
        use_driving_time_restriction_rules:
          AccountDetails?.permitted_driving_condition?.allow_driving_teams,
        allow_two_consecutive_weekly_rests:
          AccountDetails?.permitted_driving_condition
            ?.allow_two_consecutive_weekly_rests,

        vehicle_class_id:
          AccountDetails?.permitted_driving_condition?.vehicle_class_id,
        night_work_restriction:
          AccountDetails?.permitted_driving_condition?.night_work_restriction,
        night_period_from_hour:
          AccountDetails?.permitted_driving_condition?.night_period_from_hour,
        night_period_from_minutes:
          AccountDetails?.permitted_driving_condition
            ?.night_period_from_minutes,
        night_period_to_hour:
          AccountDetails?.permitted_driving_condition?.night_period_to_hour,
        night_period_to_minutes:
          AccountDetails?.permitted_driving_condition?.night_period_to_minutes,
        max_dialy_night_work_hour:
          AccountDetails?.permitted_driving_condition
            ?.max_dialy_night_work_hour,
        max_dialy_night_work_minutes:
          AccountDetails?.permitted_driving_condition
            ?.max_dialy_night_work_minutes,
        max_continuous_driving_hour:
          AccountDetails?.permitted_driving_condition
            ?.max_continuous_driving_hour || "04",
        max_continuous_driving_minutes:
          AccountDetails?.permitted_driving_condition
            ?.max_continuous_driving_minutes || "30",
        max_regular_break_hour:
          AccountDetails?.permitted_driving_condition?.max_regular_break_hour,
        max_regular_break_minutes:
          AccountDetails?.permitted_driving_condition ||
          "45"?.max_regular_break_minutes,
        max_reduced_first_break_hour:
          AccountDetails?.permitted_driving_condition
            ?.max_reduced_first_break_hour,
        max_reduced_first_break_minutes:
          AccountDetails?.permitted_driving_condition
            ?.max_reduced_first_break_minutes || "15",
        max_reduced_second_break_hour:
          AccountDetails?.permitted_driving_condition
            ?.max_reduced_second_break_hour,
        max_reduced_second_break_minutes:
          AccountDetails?.permitted_driving_condition
            ?.max_reduced_second_break_minutes,
        max_dialy_driving_hour:
          AccountDetails?.permitted_driving_condition?.max_dialy_driving_hour,
        max_dialy_driving_minutes:
          AccountDetails?.permitted_driving_condition
            ?.max_dialy_driving_minutes,
        max_regular_daily_rest_hour:
          AccountDetails?.permitted_driving_condition
            ?.max_regular_daily_rest_hour,
        max_regular_daily_rest_minutes:
          AccountDetails?.permitted_driving_condition
            ?.max_regular_daily_rest_minutes,
        max_reduced_daily_rest_hour:
          AccountDetails?.permitted_driving_condition
            ?.max_reduced_daily_rest_hour,
        max_reduced_daily_rest_minutes:
          AccountDetails?.permitted_driving_condition
            ?.max_reduced_daily_rest_minutes,
        max_split_first_daily_rest_hour:
          AccountDetails?.permitted_driving_condition
            ?.max_split_first_daily_rest_hour,
        max_split_first_daily_rest_minutes:
          AccountDetails?.permitted_driving_condition
            ?.max_split_first_daily_rest_minutes,
        max_split_second_daily_rest_hour:
          AccountDetails?.permitted_driving_condition
            ?.max_split_second_daily_rest_hour,
        max_split_second_daily_rest_minutes:
          AccountDetails?.permitted_driving_condition
            ?.max_split_second_daily_rest_minutes,
        max_weekly_driving_hour:
          AccountDetails?.permitted_driving_condition?.max_weekly_driving_hour,
        max_weekly_driving_minutes:
          AccountDetails?.permitted_driving_condition
            ?.max_weekly_driving_minutes,
        max_biweekly_driving_hour:
          AccountDetails?.permitted_driving_condition
            ?.max_biweekly_driving_hour,
        max_biweekly_driving_minutes:
          AccountDetails?.permitted_driving_condition
            ?.max_biweekly_driving_minutes,
      });
    }
  }, [AccountDetails]);

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let data = {
      allow_driving_teams: DrivingData?.allow_driving_teams,
      split_trips: DrivingData?.split_trips,
      allow_coupling_vehicles: DrivingData?.allow_coupling_vehicles,
      use_driving_time_restriction_rules:
        DrivingData?.use_driving_time_restriction_rules,
      allow_two_consecutive_weekly_rests:
        DrivingData?.allow_two_consecutive_weekly_rests,

      vehicle_class_id: DrivingData?.vehicle_class_id,
      night_work_restriction: DrivingData?.night_work_restriction,
      night_period_from_hour: DrivingData?.night_period_from_hour,
      night_period_from_minutes: DrivingData?.night_period_from_minutes,
      night_period_to_hour: DrivingData?.night_period_to_hour,
      night_period_to_minutes: DrivingData?.night_period_to_minutes,
      max_dialy_night_work_hour: DrivingData?.max_dialy_night_work_hour,
      max_dialy_night_work_minutes: DrivingData?.max_dialy_night_work_minutes,
      max_continuous_driving_hour: DrivingData?.max_continuous_driving_hour,
      max_continuous_driving_minutes:
        DrivingData?.max_continuous_driving_minutes,
      max_regular_break_hour: DrivingData?.max_regular_break_hour,
      max_regular_break_minutes: DrivingData?.max_regular_break_minutes,
      max_reduced_first_break_hour: DrivingData?.max_reduced_first_break_hour,
      max_reduced_first_break_minutes:
        DrivingData?.max_reduced_first_break_minutes,
      max_reduced_second_break_hour: DrivingData?.max_reduced_second_break_hour,
      max_reduced_second_break_minutes:
        DrivingData?.max_reduced_second_break_minutes,
      max_dialy_driving_hour: DrivingData?.max_dialy_driving_hour,
      max_dialy_driving_minutes: DrivingData?.max_dialy_driving_minutes,
      max_regular_daily_rest_hour: DrivingData?.max_regular_daily_rest_hour,
      max_regular_daily_rest_minutes:
        DrivingData?.max_regular_daily_rest_minutes,
      max_reduced_daily_rest_hour: DrivingData?.max_reduced_daily_rest_hour,
      max_reduced_daily_rest_minutes:
        DrivingData?.max_reduced_daily_rest_minutes,
      max_split_first_daily_rest_hour:
        DrivingData?.max_split_first_daily_rest_hour,
      max_split_first_daily_rest_minutes:
        DrivingData?.max_split_first_daily_rest_minutes,
      max_split_second_daily_rest_hour:
        DrivingData?.max_split_second_daily_rest_hour,
      max_split_second_daily_rest_minutes:
        DrivingData?.max_split_second_daily_rest_minutes,
      max_weekly_driving_hour: DrivingData?.max_weekly_driving_hour,
      max_weekly_driving_minutes: DrivingData?.max_weekly_driving_minutes,
      max_biweekly_driving_hour: DrivingData?.max_biweekly_driving_hour,
      max_biweekly_driving_minutes: DrivingData?.max_biweekly_driving_minutes,
    };

    let Updatedata = {
      uuid: AccountDetails?.permitted_driving_condition?.uuid,
      allow_driving_teams: DrivingData?.allow_driving_teams,
      split_trips: DrivingData?.split_trips,
      allow_coupling_vehicles: DrivingData?.allow_coupling_vehicles,
      use_driving_time_restriction_rules:
        DrivingData?.use_driving_time_restriction_rules,
      allow_two_consecutive_weekly_rests:
        DrivingData?.allow_two_consecutive_weekly_rests,
      vehicle_class_id: DrivingData?.vehicle_class_id,
      night_work_restriction: DrivingData?.night_work_restriction,
      night_period_from_hour: DrivingData?.night_period_from_hour,
      night_period_from_minutes: DrivingData?.night_period_from_minutes,
      night_period_to_hour: DrivingData?.night_period_to_hour,
      night_period_to_minutes: DrivingData?.night_period_to_minutes,
      max_dialy_night_work_hour: DrivingData?.max_dialy_night_work_hour,
      max_dialy_night_work_minutes: DrivingData?.max_dialy_night_work_minutes,
      max_continuous_driving_hour: DrivingData?.max_continuous_driving_hour,
      max_continuous_driving_minutes:
        DrivingData?.max_continuous_driving_minutes,
      max_regular_break_hour: DrivingData?.max_regular_break_hour,
      max_regular_break_minutes: DrivingData?.max_regular_break_minutes,
      max_reduced_first_break_hour: DrivingData?.max_reduced_first_break_hour,
      max_reduced_first_break_minutes:
        DrivingData?.max_reduced_first_break_minutes,
      max_reduced_second_break_hour: DrivingData?.max_reduced_second_break_hour,
      max_reduced_second_break_minutes:
        DrivingData?.max_reduced_second_break_minutes,
      max_dialy_driving_hour: DrivingData?.max_dialy_driving_hour,
      max_dialy_driving_minutes: DrivingData?.max_dialy_driving_minutes,
      max_regular_daily_rest_hour: DrivingData?.max_regular_daily_rest_hour,
      max_regular_daily_rest_minutes:
        DrivingData?.max_regular_daily_rest_minutes,
      max_reduced_daily_rest_hour: DrivingData?.max_reduced_daily_rest_hour,
      max_reduced_daily_rest_minutes:
        DrivingData?.max_reduced_daily_rest_minutes,
      max_split_first_daily_rest_hour:
        DrivingData?.max_split_first_daily_rest_hour,
      max_split_first_daily_rest_minutes:
        DrivingData?.max_split_first_daily_rest_minutes,
      max_split_second_daily_rest_hour:
        DrivingData?.max_split_second_daily_rest_hour,
      max_split_second_daily_rest_minutes:
        DrivingData?.max_split_second_daily_rest_minutes,
      max_weekly_driving_hour: DrivingData?.max_weekly_driving_hour,
      max_weekly_driving_minutes: DrivingData?.max_weekly_driving_minutes,
      max_biweekly_driving_hour: DrivingData?.max_biweekly_driving_hour,
      max_biweekly_driving_minutes: DrivingData?.max_biweekly_driving_minutes,
    };

    if (
      AccountDetails?.permitted_driving_condition !== null &&
      AccountDetails?.permitted_driving_condition?.uuid
    ) {
      modifymanagament(createAccountDrivingCondition, Updatedata);
    } else {
      modifymanagament(createAccountDrivingCondition, data);
    }
  };

  const modifymanagament = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("canbus");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const selectedDriving = DriverType.find(
    (option) => option.value === JSON.stringify(DrivingData.split_trips)
  );

  const dattt = numberHalfType?.find(
    (option) => option.value === DrivingData.max_continuous_driving_minutes
  );


  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <h4>Driver</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{ border: "solid #eee 1px", borderRadius: "5px" }}
                >
                  <div className="col-md-3">
                    <Label className="col-form-label">
                      Allow the use of driving teams
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allow_driving_teams"
                          checked={DrivingData.allow_driving_teams}
                          onChange={handleCheckboxChangeAllowDriving}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allow_driving_teams"
                        >
                          Driving teams
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Split trips when changing driver"}
                    </Label>
                    <div className="mb-3">
                      <Select
                        options={DriverType}
                        value={selectedDriving}
                        onChange={(selectedOption) =>
                          setDrivingData((prevData) => ({
                            ...prevData,
                            split_trips: selectedOption.value,
                          }))
                        }
                        placeholder={"Never"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h4 className="mb-3">Coupling</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Allow coupling of vehicles and assets
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allow_coupling_vehicles"
                          checked={DrivingData.allow_coupling_vehicles}
                          onChange={handleCheckboxCoupling}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allow_coupling_vehicles"
                        >
                          Allow coupling
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </FormGroup>

              <h4 className="mb-3">Driving Time Restrictions</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-3">
                    <Label className="col-form-label">
                      Use driving time restriction rules
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="use_driving_time_restriction_rules"
                          checked={
                            DrivingData.use_driving_time_restriction_rules
                          }
                          onChange={handleCheckboxUseDrivingTime}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="use_driving_time_restriction_rules"
                        >
                          Use driving time
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <Label className="col-form-label">
                      Vehicle classes to apply driving time restriction rules
                    </Label>
                    <div className="input-div">
                      <Select
                        options={vehicleClasssList}
                        value={vehicleClasssList.find(
                          (option) =>
                            option.value === DrivingData.vehicle_class_id
                        )}
                        onChange={(selectedOption) =>
                          setDrivingData((prevData) => ({
                            ...prevData,
                            vehicle_class_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Vehicle Class"}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <Label className="col-form-label">
                      Use Night Work restriction
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="night_work_restriction"
                          checked={DrivingData.night_work_restriction}
                          onChange={handleCheckboxUseNightWork}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="night_work_restriction"
                        >
                          Use driving time
                        </label>
                      </div>
                    </div>
                  </div>

                  {DrivingData?.night_work_restriction === 1 && (
                    <>
                      <div className="col-md-3">
                        <Label className="col-form-label d-flex">
                          {"Night period"}
                        </Label>
                        <div className="mb-3 row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.night_period_from_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  night_period_from_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.night_period_from_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  night_period_from_hour: selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.night_period_to_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  night_period_to_minutes: selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.night_period_to_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  night_period_to_hour: selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <Label className="col-form-label d-flex">
                          {"Maximum daily work with night work	"}
                        </Label>
                        <div className="mb-3 row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_dialy_night_work_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_dialy_night_work_hour:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_dialy_night_work_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_dialy_night_work_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Allow the use of two consecutive reduced weekly rests
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allow_two_consecutive_weekly_rests"
                          checked={
                            DrivingData.allow_two_consecutive_weekly_rests
                          }
                          onChange={handleCheckboxWeekly}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allow_two_consecutive_weekly_rests"
                        >
                          Reduced weekly rests
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div className="page-table-row general-ac">
                <table
                  className={`table table-striped `}
                  style={{ width: "750px" }}
                >
                  <thead>
                    <tr>
                      <th scope={`col`}>{`DESCRIPTION`}</th>
                      <th scope={`col`}>{`TIME`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Maximum continuous driving time</td>
                      <td>
                        <div className="row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_continuous_driving_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_continuous_driving_hour:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_continuous_driving_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_continuous_driving_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Minimum regular break time</td>
                      <td>
                        <div className="row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_regular_break_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_regular_break_hour: selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_regular_break_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_regular_break_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Minimum reduced first break time </td>
                      <td>
                        <div className="row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_reduced_first_break_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_reduced_first_break_hour:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_reduced_first_break_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_reduced_first_break_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Minimum reduced second break time </td>
                      <td>
                        <div className="row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_reduced_second_break_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_reduced_second_break_hour:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_reduced_second_break_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_reduced_second_break_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Maximum daily driving time</td>
                      <td>
                        <div className="row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_dialy_driving_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_dialy_driving_hour: selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_dialy_driving_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_dialy_driving_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Minimum regular daily rest time</td>
                      <td>
                        <div className="row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_regular_daily_rest_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_regular_daily_rest_hour:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_regular_daily_rest_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_regular_daily_rest_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Minimum reduced daily rest time</td>
                      <td>
                        <div className="row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_reduced_daily_rest_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_reduced_daily_rest_hour:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_reduced_daily_rest_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_reduced_daily_rest_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Minimum split first daily rest time</td>
                      <td>
                        <div className="row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_split_first_daily_rest_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_split_first_daily_rest_hour:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_split_first_daily_rest_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_split_first_daily_rest_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Minimum split second daily rest time</td>
                      <td>
                        <div className="row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_split_second_daily_rest_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_split_second_daily_rest_hour:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_split_second_daily_rest_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_split_second_daily_rest_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Maximum weekly driving time</td>
                      <td>
                        <div className="row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_weekly_driving_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_weekly_driving_hour: selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_weekly_driving_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_weekly_driving_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Maximum biweekly driving time</td>
                      <td>
                        <div className="row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_biweekly_driving_hour
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_biweekly_driving_hour:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_split_first_daily_rest_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_split_first_daily_rest_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Minimum split first daily rest time</td>
                      <td>
                        <div className="row">
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHourType}
                              value={numberHourType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_biweekly_driving_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_biweekly_driving_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                          <div className="col-md-4 selectionOption">
                            <Select
                              options={numberHalfType}
                              value={numberHalfType?.find(
                                (option) =>
                                  option.value ===
                                  DrivingData.max_split_first_daily_rest_minutes
                              )}
                              onChange={(selectedOption) =>
                                setDrivingData((prevData) => ({
                                  ...prevData,
                                  max_split_first_daily_rest_minutes:
                                    selectedOption.value,
                                }))
                              }
                              placeholder={"00"}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrivingCondition;
