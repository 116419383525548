import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ConfigDB from "../../config";
import Select from "react-select";
import { getVehicleList } from "../../services/VehicleService";
import { getDriverList } from "../../services/TripService";
import {
  getPlaceReportList,
  getRoundTripReportList,
} from "../../services/ReportsService";
import DatePicker from "react-multi-date-picker";
import { toast } from "react-toastify";
import Utils from "../../utils";

const showReports = [
  {
    value: 1,
    label: "Detailed",
  },
  { value: 2, label: "Summarized" },
];

const showInformation = [
  {
    value: 1,
    label: "Vehicle",
  },
  { value: 2, label: "Driver" },
];

const RoundTripsReports = ({ setActiveTab }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [open, setOpen] = useState(false);
  const [permenenceData, setPermenenceData] = useState({
    show_report: "",
    vehicle_id: "",
    driver_id: "",
    place_id: "",
    show_information_by: "",
    ignition_must_be: "0",
    vehicle_place_least: "",
    subject: "",
    email: "",
    placeA: "",
    placeB: "",
    show_information_id: "",
  });

  const [modifyModal, setModifyModal] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  const [placeList, setPlaceList] = useState([]);

  const [DriverList, setDriverList] = useState([]);
  const [PlaceDestinationList, setPlaceDestinationList] = useState([]);

  const formattedStartDate = selectionRange.startDate.toLocaleDateString();
  const formattedEndDate = selectionRange.endDate.toLocaleDateString();
  const [dateRange, setDateRange] = useState("");

  const handleChange = (range) => {
    setDateRange(range);
  };
  const onDatepickerOpen = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const handleSendEmailOpen = () => {
    setModifyModal(true);
  };

  const handleSendEmailClose = () => {
    setModifyModal(false);
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setPermenenceData({
      ...permenenceData,
      ignition_must_be: newValue,
    });
  };

  useEffect(
    function () {
      let data = { ...params };
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => { });
    },
    [params]
  );

  useEffect(function () {
    getPlaceReportList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].source_place,
          });
        }
        setPlaceList(list);
      })
      .catch((err) => { });
  }, []);

  useEffect(function () {
    getPlaceReportList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].destination_place,
          });
        }
        setPlaceDestinationList(list);
      })
      .catch((err) => { });
  }, []);

  useEffect(
    function () {
      let data = params;
      getDriverList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setDriverList(list);
        })
        .catch((err) => { });
    },
    [params]
  );

  const date = new Date(dateRange);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const handleAddFuelCostForm = (e) => {
    e.preventDefault();

    if (!dateRange) {
      toast.error("Please select date.");
      return;
    }

    if (!permenenceData.show_information_id) {
      toast.error("Please select vehicle.");
      return; // Stop further execution if no vehicle is selected
    }

    if (!permenenceData.placeA && !permenenceData.placeB) {
      toast.error("Please select place A and place B.");
      return; // Stop further execution if no vehicle is selected
    }

    if (!permenenceData.vehicle_place_least) {
      toast.error("Please select vehicle min");
      return; // Stop further execution if no vehicle is selected
    }
    let data = {
      vehicleIds: permenenceData?.show_information_id,
      startDate: formattedDate,
      placeA: permenenceData?.placeA,
      placeB: permenenceData?.placeB,
      ignition: permenenceData?.ignition_must_be,
      minStopoverTime: permenenceData?.vehicle_place_least,
    };

    modifyFuel(getRoundTripReportList, data);
  };
  const modifyFuel = (actionFunction, data) => {
    actionFunction(data)
      .then((response) => {
        const dataToSend = {
          vehicleIds: permenenceData?.show_information_id,
          startDate: formattedDate,
          placeA: permenenceData?.placeA,
          placeB: permenenceData?.placeB,
          ignition: permenenceData?.ignition_must_be,
          minStopoverTime: permenenceData?.vehicle_place_least,
        };


        if (!response?.data || response?.data?.trips === null || response?.data?.trips?.length <= 0) {
          toast.error("No data found.");
          return; // Stop further execution if no vehicle is selected
        }

        localStorage.setItem("rounTripReportData", JSON.stringify(dataToSend));
        window.open("/round-trip-reports", "_blank");
      })
      .catch((err) => { });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleAddFuelCostForm}>
              <h6>ROUNDTRIPS</h6>
              <h6>
                This report allows you to calculate how many roundtrips were
                made and what was the total mileage and time. In the detailed
                version, you can see the exact data for each trip back and
                forth.
              </h6>
              <div className="d-flex justify-content-start">
                <div className="me-1">
                  <Button
                    color="success btn-info"
                    type={`submit`}
                    className={`btn-square`}
                  >{`Generate`}</Button>
                </div>

                {/* <div>
                  <Button
                    color="success btn-info"
                    type={`button`}
                    onClick={handleSendEmailOpen}
                    className={`btn-square`}
                  >{`Send Report By EMail`}</Button>
                </div> */}
              </div>
            </Form>
          </div>
          <div className="card card-primary general-ac">
            <Form method={`post`}>
              <h6>Configuration</h6>

              <div className="row">
                <div className="col-md-3 datePicker">
                  <FormGroup>
                    <Label className="col-form-label">{"Period"}</Label>
                    <div style={{
                      border: "1px solid #CCCCCC",
                      borderRadius: "5px",
                      height: "40px",
                      margin: "1px 0",
                      padding: "2px 5px",
                      paddingRight: "30px",
                      width: "100%",
                    }}>
                      <DatePicker
                        selected={dateRange}
                        onChange={handleChange}
                        dateFormat="yyyy-MM-dd"
                        calendarPosition="bottom-center"
                        placeholder="Select Date"

                      />
                      <i class="bi bi-calendar-event" style={{ position: "absolute", marginTop: "5px" }}></i>
                    </div>


                  </FormGroup>
                </div>

                <div className="col-md-3">
                  <Label className="col-form-label">
                    {"Show information by"}
                  </Label>
                  <div className="input-div">
                    <Select
                      options={showInformation}
                      value={showInformation?.find(
                        (option) =>
                          option.value ===
                          JSON.stringify(permenenceData.show_information_by)
                      )}
                      onChange={(selectedOption) =>
                        setPermenenceData((prevData) => ({
                          ...prevData,
                          show_information_by: selectedOption.value,
                        }))
                      }
                      placeholder={"Show information by"}
                    />
                  </div>
                </div>

                {permenenceData.show_information_by === 1 && (
                  <div className="col-md-3">
                    <Label className="col-form-label">{"Vehicle"}</Label>
                    <div className="input-div">
                      <Select
                        options={vehicleList}
                        value={vehicleList?.find(
                          (option) =>
                            option.value ===
                            JSON.stringify(permenenceData.show_information_id)
                        )}
                        onChange={(selectedOption) =>
                          setPermenenceData((prevData) => ({
                            ...prevData,
                            show_information_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Vehicle"}
                      />
                    </div>
                  </div>
                )}

                {permenenceData.show_information_by === 2 && (
                  <div className="col-md-6">
                    <Label className="col-form-label">{"Driver"}</Label>
                    <div className="input-div">
                      <Select
                        options={DriverList}
                        value={DriverList?.find(
                          (option) =>
                            option.value ===
                            JSON.stringify(permenenceData.show_information_id)
                        )}
                        onChange={(selectedOption) =>
                          setPermenenceData((prevData) => ({
                            ...prevData,
                            show_information_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Driver"}
                      />
                    </div>
                  </div>
                )}

                <div className="col-md-3">
                  <FormGroup>
                    <Label className="col-form-label">{"Show Reports"}</Label>

                    <Select
                      options={showReports}
                      value={showReports.find(
                        (option) => option.value === permenenceData.show_report
                      )}
                      onChange={(selectedOption) =>
                        setPermenenceData((prevData) => ({
                          ...prevData,
                          show_report: selectedOption.value,
                        }))
                      }
                    />
                  </FormGroup>
                </div>

                <div className="col-md-3">
                  <Label className="col-form-label">{"Place A"}</Label>
                  <div className="input-div">
                    <Select
                      options={placeList}
                      value={placeList?.find(
                        (option) =>
                          option.value === JSON.stringify(permenenceData.placeA)
                      )}
                      onChange={(selectedOption) =>
                        setPermenenceData((prevData) => ({
                          ...prevData,
                          placeA: selectedOption.label,
                        }))
                      }
                      placeholder={"Select Place"}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <Label className="col-form-label">{"Place B"}</Label>
                  <div className="input-div">
                    <Select
                      options={PlaceDestinationList}
                      value={PlaceDestinationList?.find(
                        (option) =>
                          option.value === JSON.stringify(permenenceData.placeB)
                      )}
                      onChange={(selectedOption) =>
                        setPermenenceData((prevData) => ({
                          ...prevData,
                          placeB: selectedOption.label,
                        }))
                      }
                      placeholder={"Select Place"}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <Label className="col-form-label">
                    {"Vehicle must stay in place at least (minutes)"}
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="vehicle Place latest"
                      name={`Vehicle must stay in place at least (minutes)	
                      `}
                      onChange={(e) => {
                        setPermenenceData({
                          ...permenenceData,
                          vehicle_place_least: e.target.value,
                        });
                      }}
                      value={permenenceData.vehicle_place_least}
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <Label className="col-form-label">
                    Ignition must be disconnected in places{" "}
                  </Label>
                  <div className="input-div">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="ignition_must_be"
                        checked={permenenceData.ignition_must_be}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ignition_must_be"
                      >
                        Ignition must be disconnected in places{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`Save`}
                  className={`btn-square `}
                >{`Save`}</Button>
              </div> */}
            </Form>
          </div>
        </div>
      </div>
      <Modal isOpen={modifyModal}>
        <ModalHeader>Send Report By Email </ModalHeader>
        <Form method={`post`}>
          <ModalBody>
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <Label className="col-form-label">{"Subject"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      name={`subject`}
                      onChange={(e) => {
                        setPermenenceData({
                          ...permenenceData,
                          subject: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={permenenceData.subject}
                    // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Email"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      name={`email`}
                      onChange={(e) => {
                        setPermenenceData({
                          ...permenenceData,
                          email: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={permenenceData.email}
                    // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={handleSendEmailClose}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success btn-info"
              type={`submit`}
              className={`btn-square`}
            >{`Submit`}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default RoundTripsReports;
