import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import { actionDailyReports } from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import * as XLSX from "xlsx";

const DailySummaryList = () => {
  const [alarmsDetails, setAlarmsDetails] = useState("");
  const [alarmsDetailsList, setAlarmsDetailsList] = useState([]);
  const storedData = localStorage.getItem("DailySummaryReportData");
  const parsedData = JSON.parse(storedData);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const alarms = event.data;
      setAlarmsDetails(alarms);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    // if (alarmsDetails) {
    let data = {
      show_report: parsedData?.show_report,
      reportDate: parsedData?.reportDate,
      show_days_of_week: parsedData?.show_days_of_week,
      alarm_to_show: parsedData?.alarm_to_show,
      aggregate_alarms: parsedData?.aggregate_alarms,
      show_beginning: parsedData?.show_beginning,
      show_end: parsedData?.show_end,
      number_of_trips: parsedData?.number_of_trips,
      gps_mileage: parsedData?.gps_mileage,
      canbus_mileage: parsedData?.canbus_mileage,
      travel_time: parsedData?.travel_time,
      average_speed: parsedData?.average_speed,
      first_driver: parsedData?.first_driver,
      last_driver: parsedData?.last_driver,
      different_driver: parsedData?.different_driver,
      fuel_consumption: parsedData?.fuel_consumption,
    };
    actionDailyReports(data)
      .then((response) => {
        setAlarmsDetailsList(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
    // }
  }, []);

  const exportToExcel = () => {
    if (!alarmsDetailsList || alarmsDetailsList.length === 0) {
      toast.error("No data to export!");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(alarmsDetailsList);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alarms");

    XLSX.writeFile(workbook, "daily_summary.xlsx");
  };

  return (
    <div>
      <section>
        <div className="tablePage">
          <div className="container-fluid">
            <div className="tablePageMain mt-2">
              <div>
                <div className="logoimg">
                  <img src={Logo} alt="logoimg" />
                </div>
              </div>
              <div className>
                <div className="Content">
                  <div>
                    <h3>SINGLE DAY SUMMARY</h3>
                    <p className="VehicleDate">
                      Report generated on 6 November 2024 07:20
                    </p>
                    <p>From date month year time to date month year time</p>
                    <h5 style={{ cursor: "pointer" }} onClick={exportToExcel}>
                      Export to Excel
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-table mt-4 table-responsive">
              <table className="table table-striped table-hover">
                <thead className="table-light">
                  <tr>
                    <th />
                    <th colSpan={3} className="text-center">
                      Beginning of day
                    </th>
                    <th colSpan={2} className="text-center">
                      Total
                    </th>
                    <th colSpan={10} className="text-center">
                      Last trip
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">Vehicle</th>
                    <th scope="col">First driver</th>
                    <th scope="col">Beginning of day</th>
                    <th scope="col">Odometer start (km)</th>
                    <th scope="col">Number of trips</th>
                    <th scope="col">Mileage today (km)</th>
                    <th scope="col">Driver</th>
                    <th scope="col">Departure time</th>
                    <th scope="col">Departure address</th>
                    <th>Arrival time</th>
                    <th scope="col">Arrival address</th>
                    <th scope="col">Mileage (km)</th>
                    <th scope="col">Odometer (km)</th>
                    <th scope="col">On trip</th>
                  </tr>
                </thead>
                <tbody>
                  {alarmsDetailsList?.map((item) => {
                    const time = new Date(
                      item.tripStartTime
                    ).toLocaleTimeString("en-US", { hour12: false });

                    const arrivaltime = new Date(
                      item.tripEndTime
                    ).toLocaleTimeString("en-US", { hour12: false });

                    return (
                      <>
                        <tr>
                          <th scope="row">{item.vehicle_name}</th>
                          <td>{item.driver_name}</td>
                          <td>{time}</td>
                          <td>{item.trip_odometer}</td>
                          <td>{item.trip_num}</td>
                          <td>{item.driver_name}</td>
                          <td>-</td>
                          <td>{time}</td>
                          <td>{item.departureName}</td>
                          <td>{arrivaltime}</td>
                          <td>{item.arrivalName}</td>
                          <td>{item.CANBUS_mileage}</td>
                          <td>{item.total_odometer}</td>

                          <td />
                        </tr>
                      </>
                    );
                  })}

                  {/*<tr>*/}
                  {/*<td colSpan={3} />*/}
                  {/*<td>Total</td>*/}
                  {/*<td>205</td>*/}
                  {/*<td>2 906,9</td>*/}
                  {/*<td colSpan={10} />*/}
                  {/*</tr>*/}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DailySummaryList;
