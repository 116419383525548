import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate, useParams } from "react-router-dom";

import { getDriverAllDetails } from "../../services/EmployeeService";
import GeneralArea from "./GeneralArea";
import MapArea from "./MapArea";
import { addZonedevice } from "../../services/TripService";
import { Button } from "reactstrap";
import { Marker } from "@react-google-maps/api";

const AddAreas = ({ setAddAreaStatus, AreaData }) => {
  const [activeTab, setActiveTab] = useState("general");
  const navigate = useNavigate();
  const [placeData, setPlaceData] = useState({
    radius_latitude: "",
    radius_longitude: "",
    radius_zonename: "",
    place_id: "",
    area_type: "",
    vehicle_radius: "",
    department_id: "",
    segement_id: "",
  });

  const [geoFenceRadius, setGeoFenceRadius] = useState(null);
  const [zone, setZone] = useState("");
  const [place, setPlace] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (AreaData) {
      setPlaceData({
        radius_latitude: AreaData?.radius_latitude || "",
        radius_longitude: AreaData?.radius_longitude || "",
        radius_zonename: AreaData?.radius_zonename || "",
        place_id: AreaData?.place_id || "",
        area_type: AreaData?.area_type || "",
        vehicle_radius: AreaData?.vehicle_radius || "",
        department_id: AreaData?.department_id || "",
        segement_id: AreaData?.segement_id || "",
      });
      setZone(AreaData?.radius_zonename);
      setPlace(AreaData?.radius_zonename);
      setSelectedPlace(AreaData?.place_id);

    
    }
  }, [AreaData]);

  const { uuid } = useParams();

  const handleonFormSubmit = (e) => {
    e.preventDefault();

    let data = {
      radius_latitude:
        placeData.area_type === "1"
          ? geoFenceRadius?.center?.lat
          : geoFenceRadius?.center?.lat,
      radius_longitude:
        placeData.area_type === "1"
          ? geoFenceRadius?.center?.lng
          : geoFenceRadius?.center?.lng,
      radius_zonename: placeData.area_type === "1" ? zone : place,
      place_id: placeData.area_type === "2" ? selectedPlace?.value : "",
      area_type: placeData.area_type,
      vehicle_radius: geoFenceRadius?.radius,
      department_id: placeData?.department_id,
      segment_id: placeData?.segement_id,
    };

    let updateData = {
      uuid: AreaData?.uuid,
      radius_latitude:
        placeData.area_type === "1" ? geoFenceRadius?.lat : geoFenceRadius?.lat,
      radius_longitude:
        placeData.area_type === "1" ? geoFenceRadius?.lng : geoFenceRadius?.lng,
      radius_zonename: placeData.area_type === "1" ? zone : place,
      place_id: placeData.area_type === "2" ? selectedPlace?.value : "",
      area_type: placeData.area_type,
      vehicle_radius: geoFenceRadius?.radius,
      department_id: placeData?.department_id,
      segment_id: placeData?.segement_id,
    };

    if (AreaData?.uuid) {
      modifyPlace(addZonedevice, updateData);
    } else {
      modifyPlace(addZonedevice, data);
    }
  };

  const modifyPlace = (actionFunction, data) => {
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);

        setAddAreaStatus(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="inner-header py-3 d-none">
        <div className="left-block"></div>
      </div>
      <div className="inner-header-back">
        <div className="left-col">
          <button
            type="buttton"
            className="back-btn"
            onClick={() => {
              setAddAreaStatus(false);
            }}
          >
            <i class="bi bi-chevron-left"></i> Back
          </button>
        </div>
        <div className="right-block w-auto">
          <Button
            type={`button`}
            onClick={handleonFormSubmit}
            className={`add-btn btn  float-right`}
          >
            {`Save`} <i class="bi bi-check-circle-fill checkIcon"></i>
          </Button>
        </div>
      </div>

      <div className="leaflet-control-management managementui-tab leaflet-control-var">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "general" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("general");
                }}
              >
                <span data-href="#tab-1">General</span>
              </li>
            </ul>

            <ul className="tabs-management">
              <li
                className={`nav-link ${activeTab === "map" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("map");
                }}
              >
                <span data-href="#tab-1">Map</span>
              </li>
            </ul>
          </nav>

          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "general" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <GeneralArea
                setActiveTab={setActiveTab}
                AreaData={AreaData}
                setAddAreaStatus={setAddAreaStatus}
                setPlaceDatas={setPlaceData}
                setZones={setZone}
                setPlaces={setPlace}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "map" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <MapArea
                setActiveTab={setActiveTab}
                AreaData={AreaData}
                setAddAreaStatus={setAddAreaStatus}
                setGeoFenceRadiuss={setGeoFenceRadius}
                setSelectedPlaces={setSelectedPlace}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAreas;
