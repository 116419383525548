import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import moment from "moment";
import {
  getTachnoDriverList,
  getTachnoGraphCountryList,
  getTachnoGraphDriverPdf,
  getTachnoGraphTimeZoneList,
  getTachnoVehicleList,
} from "../../services/TachnographService";
import { toast } from "react-toastify";
import Utils from "../../utils";

const ReportTypeList = [{ value: 0, label: "Activity Analysis" }];
const RegulationList = [
  { value: "0", label: "AETR Agreement" },
  { value: "1", label: "Regulation 1258/2024" },
];

const TachnographReport = () => {
  const [selectedOption, setSelectedOption] = useState("driver");

  const [selectedOptionFromate, setSelectedOptionFromate] = useState("pdf");
  const [vehicleDate, setVehicleDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [vehicleDates, setVehicleDates] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [vehicleData, setVehicleData] = useState({
    techograph_type: "",
    driver: "",
    regulation: "",
    penalty: "",
    vehicle: "",
  });

  const [CountryTachnoList, setCountryTachnoList] = useState([]);
  const [timeZoneTachnoList, setTimeZoneTachnoList] = useState([]);
  const [driverTachnoList, setDriverTachnoList] = useState([]);
  const [vehicleTachnoList, setVehicleTachnoList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let errorsObj = {
    techograph_type: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(function () {
    getTachnoGraphCountryList()
      .then((response) => {
        setCountryTachnoList(response);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getTachnoGraphTimeZoneList()
      .then((response) => {
        setTimeZoneTachnoList(response);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getTachnoDriverList()
      .then((response) => {
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDriverTachnoList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getTachnoVehicleList()
      .then((response) => {
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setVehicleTachnoList(list);
      })
      .catch((err) => {});
  }, []);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRadioChangeFormate = (event) => {
    setSelectedOptionFromate(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // let error = false;
    // let errorObj = { ...errorsObj };

    // console.log("errorObj", errorObj);
    // if (!vehicleData.techograph_type) {
    //   errorObj.techograph_type = `Report Type required.`;
    //   error = true;
    // }

    // setErrors(errorObj);
    // if (error) return;

    setIsLoading(true);

    let data = {
      driverId: vehicleData.driver,
      reportType: "activity_times",
      startDate: vehicleDate,
      endDate: vehicleDates,
      onlyInfringementsGraphs: true,
      regulation: 0,
      penalty: 0,
      ignoreCountrySelectedInfringements: true,
      activitiesGraphs: true,
      activitiesTables: true,
      infringementsLists: true,
      timezone: "New Delhi",
    };

    getTachnoGraphDriverPdf(data)
      .then((response) => {
        toast.success(response.data.message);
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "tachograph-report.pdf";
        link.click();
        window.URL.revokeObjectURL(pdfUrl);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      });
  };

  return (
    <div>
      {isLoading ? (
        <>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary general-ac">
                <Form method={`post`} onSubmit={handleSubmit}>
                  <h4>Reports</h4>
                  <FormGroup>
                    <div
                      className="row"
                      style={{
                        border: "solid #eee 1px",
                        borderRadius: "5px",
                        paddingBottom: "12px",
                      }}
                    >
                      <div className="row">
                        <Label className="col-form-label">{"Analyse"}</Label>
                        <div className="col-md-4">
                          <div className="input-div">
                            <label>
                              <input
                                type="radio"
                                value="driver"
                                checked={selectedOption === "driver"}
                                onChange={handleRadioChange}
                                className="me-2"
                              />
                              Driver
                            </label>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-div">
                            <label>
                              <input
                                type="radio"
                                value="vehicle"
                                checked={selectedOption === "vehicle"}
                                onChange={handleRadioChange}
                                className="me-2"
                              />
                              Vehicle
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <Label className="col-form-label">
                          {"Report Format"}
                        </Label>
                        <div className="col-md-4">
                          <div className="input-div">
                            <label>
                              <input
                                type="radio"
                                value="pdf"
                                checked={selectedOptionFromate === "pdf"}
                                onChange={handleRadioChangeFormate}
                                className="me-2"
                              />
                              PDF
                            </label>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-div">
                            <label>
                              <input
                                type="radio"
                                value="xlsx"
                                checked={selectedOptionFromate === "xlsx"}
                                onChange={handleRadioChangeFormate}
                                className="me-2"
                              />
                              XLSX (Excel)
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <Label className="col-form-label">
                          {"Report type"}
                        </Label>
                        <div className="input-div">
                          <Select
                            options={ReportTypeList}
                            value={ReportTypeList?.find(
                              (option) =>
                                option.value === vehicleData.techograph_type
                            )}
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                setVehicleData((prevData) => ({
                                  ...prevData,
                                  techograph_type: selectedOption.value,
                                }));
                              }
                            }}
                            placeholder="Select Report Type"
                          />

                          {errors.techograph_type && (
                            <span className="input-error">
                              {errors.techograph_type}
                            </span>
                          )}
                        </div>
                      </div>

                      {selectedOption === "vehicle" &&
                      vehicleData.techograph_type === 0 ? (
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <Label className="col-form-label">
                                {"Vehicle"}
                              </Label>
                              <div className="input-div">
                                <Select
                                  options={vehicleTachnoList}
                                  value={vehicleTachnoList?.find(
                                    (option) =>
                                      option.value === vehicleData.vehicle
                                  )}
                                  onChange={(selectedOption) => {
                                    if (selectedOption) {
                                      setVehicleData((prevData) => ({
                                        ...prevData,
                                        vehicle: selectedOption.value,
                                      }));
                                    }
                                  }}
                                  placeholder="Select Vehicle"
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <Label className="col-form-label">
                                {"Regulation"}
                              </Label>
                              <div className="input-div">
                                <Select
                                  options={RegulationList}
                                  value={RegulationList?.find(
                                    (option) =>
                                      option.value === vehicleData.regulation
                                  )}
                                  onChange={(selectedOption) => {
                                    if (selectedOption) {
                                      setVehicleData((prevData) => ({
                                        ...prevData,
                                        regulation: selectedOption.value,
                                      }));
                                    }
                                  }}
                                  placeholder="Select Regulation"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {vehicleData.techograph_type === 0 && (
                            <>
                              <div className="row">
                                <div className="col-md-6">
                                  <Label className="col-form-label">
                                    {"Driver"}
                                  </Label>
                                  <div className="input-div">
                                    <Select
                                      options={driverTachnoList}
                                      value={driverTachnoList?.find(
                                        (option) =>
                                          option.value === vehicleData.driver
                                      )}
                                      onChange={(selectedOption) => {
                                        if (selectedOption) {
                                          setVehicleData((prevData) => ({
                                            ...prevData,
                                            driver: selectedOption.value,
                                          }));
                                        }
                                      }}
                                      placeholder="Select Driver"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <Label className="col-form-label">
                                    {"Regulation"}
                                  </Label>
                                  <div className="input-div">
                                    <Select
                                      options={RegulationList}
                                      value={RegulationList?.find(
                                        (option) =>
                                          option.value ===
                                          vehicleData.regulation
                                      )}
                                      onChange={(selectedOption) => {
                                        if (selectedOption) {
                                          setVehicleData((prevData) => ({
                                            ...prevData,
                                            regulation: selectedOption.value,
                                          }));
                                        }
                                      }}
                                      placeholder="Select Regulation"
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      <div className="row">
                        <div className="col-md-6">
                          <Label>Start Date</Label>

                          <div className="input-group">
                            <input
                              type="date"
                              id="vehicleDate"
                              className="form-control"
                              value={vehicleDate}
                              onChange={(e) => setVehicleDate(e.target.value)}
                              placeholder="Vehicle Date"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <Label>End Date</Label>

                          <div className="input-group">
                            <input
                              type="date"
                              id="vehicleDate"
                              className="form-control"
                              value={vehicleDates}
                              onChange={(e) => setVehicleDates(e.target.value)}
                              placeholder="Vehicle Date"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormGroup>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      color="success btn-info"
                      type={`submit`}
                      className={`btn-square `}
                    >{`Submit`}</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TachnographReport;
