import React, { useEffect, useState } from "react";
import { getTachnoVehicleList } from "../../services/TachnographService";
import moment from "moment/moment";

const TachnographVehicle = () => {
  const [vehicleTachnoList, setVehicleTachnoList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(function () {
    getTachnoVehicleList()
      .then((response) => {
        setVehicleTachnoList(response.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <div>
      <div className="container-event">
        <div className="page-header-row company-section-Area">
          <div className="row">
            <div className="col">
              <div className=" company-section row row-cols-1 row-cols-lg-3 g-2 g-lg-3">
                <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Search</p>
                    <div className="search-bar">
                      <i className="bi bi-search"></i>
                      <input
                        type="text"
                        className="form-control"
                        // value={params.search}
                        // onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`}>{`Register Number`}</th>
                <th scope={`col`}>{`Country`}</th>
                <th scope={`col`}>{`Odometer(Km)`}</th>
                <th scope={`col`}>{`Last DownLoad`}</th>
                <th scope={`col`}>{`Next Calibration`}</th>
                <th scope={`col`}>{`Active`}</th>
              </tr>
            </thead>
            <tbody>
              {vehicleTachnoList &&
                vehicleTachnoList?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ cursor: "pointer", color: "#45abe3" }}>
                        {item.registerNumber}
                      </td>
                      <td style={{ cursor: "pointer" }}>{item.country}</td>

                      <td style={{ cursor: "pointer" }}>{item.odometer}</td>
                      <td style={{ cursor: "pointer" }}>
                        {moment(item.lastDownload).format("DD/MM/YYYY")}
                      </td>

                      <td style={{ cursor: "pointer" }}>
                        {item.nextCalibration}
                      </td>
                      <td style={{ cursor: "pointer" }}>{item.active}</td>
                    </tr>
                  );
                })}

              {vehicleTachnoList && vehicleTachnoList?.length === 0 && (
                <tr>
                  <td
                    colSpan={7}
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TachnographVehicle;
