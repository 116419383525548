import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import moment from "moment";
import {
  getTachnoDriverList,
  getTachnoGraphCountryList,
  getTachnoGraphDriverAnalysis,
  getTachnoGraphDriverPdf,
  getTachnoGraphTimeZoneList,
  getTachnoVehicleList,
} from "../../services/TachnographService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import TachnoGraphAnaysisDetails from "./TachnoGraphAnaysisDetails";

const ReportTypeList = [{ value: 0, label: "Activity Analysis" }];
const RegulationList = [
  { value: "0", label: "AETR Agreement" },
  { value: "1", label: "Regulation 1258/2024" },
];

const TachnoGraphAnalysis = () => {
  const [selectedOption, setSelectedOption] = useState("driver");

  const [selectedOptionFromate, setSelectedOptionFromate] = useState("pdf");
  const [vehicleDate, setVehicleDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [vehicleDates, setVehicleDates] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [vehicleData, setVehicleData] = useState({
    techograph_type: "",
    driver: "",
    regulation: "",
    penalty: "",
    vehicle: "",
  });

  const [CountryTachnoList, setCountryTachnoList] = useState([]);
  const [timeZoneTachnoList, setTimeZoneTachnoList] = useState([]);
  const [driverTachnoList, setDriverTachnoList] = useState([]);
  const [vehicleTachnoList, setVehicleTachnoList] = useState([]);
  const [TachnoDriverDetailsList, setTachnoDriverDetailsList] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(function () {
    getTachnoGraphCountryList()
      .then((response) => {
        setCountryTachnoList(response);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getTachnoGraphTimeZoneList()
      .then((response) => {
        setTimeZoneTachnoList(response);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getTachnoDriverList()
      .then((response) => {
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDriverTachnoList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getTachnoVehicleList()
      .then((response) => {
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setVehicleTachnoList(list);
      })
      .catch((err) => {});
  }, []);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRadioChangeFormate = (event) => {
    setSelectedOptionFromate(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      driverId: vehicleData.driver,
      startDate: vehicleDate,
      endDate: vehicleDates,
      onlyInfringementsGraphs: true,
      regulation: 0,
      penalty: 0,
      ignoreCountrySelectedInfringements: true,

      //   timezone: "New Delhi",
    };

    getTachnoGraphDriverAnalysis(data)
      .then((response) => {
        toast.success(response.data.message);
        setTachnoDriverDetailsList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      });
  };

  return (
    <div>
      <div className="row">
        {loading ? (
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            <div className="col-md-12">
              {TachnoDriverDetailsList ? (
                <TachnoGraphAnaysisDetails
                  setTachnoDriverDetailsList={setTachnoDriverDetailsList}
                  TachnoDriverDetailsList={TachnoDriverDetailsList}
                />
              ) : (
                <>
                  <div className="card card-primary general-ac">
                    <Form method={`post`} onSubmit={handleSubmit}>
                      <h4>Reports</h4>
                      <FormGroup>
                        <div
                          className="row"
                          style={{
                            border: "solid #eee 1px",
                            borderRadius: "5px",
                            paddingBottom: "12px",
                          }}
                        >
                          <div className="row">
                            <Label className="col-form-label">
                              {"Analyse"}
                            </Label>
                            <div className="col-md-4">
                              <div className="input-div">
                                <label>
                                  <input
                                    type="radio"
                                    value="driver"
                                    checked={selectedOption === "driver"}
                                    onChange={handleRadioChange}
                                    className="me-2"
                                  />
                                  Driver
                                </label>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="input-div">
                                <label>
                                  <input
                                    type="radio"
                                    value="vehicle"
                                    checked={selectedOption === "vehicle"}
                                    onChange={handleRadioChange}
                                    className="me-2"
                                  />
                                  Vehicle
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <Label className="col-form-label">
                              {"Report type"}
                            </Label>
                            <div className="input-div">
                              <Select
                                options={ReportTypeList}
                                value={ReportTypeList?.find(
                                  (option) =>
                                    option.value === vehicleData.techograph_type
                                )}
                                onChange={(selectedOption) => {
                                  if (selectedOption) {
                                    setVehicleData((prevData) => ({
                                      ...prevData,
                                      techograph_type: selectedOption.value,
                                    }));
                                  }
                                }}
                                placeholder="Select Report Type"
                              />
                            </div>
                          </div>

                          {selectedOption === "vehicle" &&
                          vehicleData.techograph_type === 0 ? (
                            <>
                              <div className="row">
                                <div className="col-md-4">
                                  <Label className="col-form-label">
                                    {"Vehicle"}
                                  </Label>
                                  <div className="input-div">
                                    <Select
                                      options={vehicleTachnoList}
                                      value={vehicleTachnoList?.find(
                                        (option) =>
                                          option.value === vehicleData.vehicle
                                      )}
                                      onChange={(selectedOption) => {
                                        if (selectedOption) {
                                          setVehicleData((prevData) => ({
                                            ...prevData,
                                            vehicle: selectedOption.value,
                                          }));
                                        }
                                      }}
                                      placeholder="Select Vehicle"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <Label className="col-form-label">
                                    {"Regulation"}
                                  </Label>
                                  <div className="input-div">
                                    <Select
                                      options={RegulationList}
                                      value={RegulationList?.find(
                                        (option) =>
                                          option.value ===
                                          vehicleData.regulation
                                      )}
                                      onChange={(selectedOption) => {
                                        if (selectedOption) {
                                          setVehicleData((prevData) => ({
                                            ...prevData,
                                            regulation: selectedOption.value,
                                          }));
                                        }
                                      }}
                                      placeholder="Select Regulation"
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {vehicleData.techograph_type === 0 && (
                                <div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <Label className="col-form-label">
                                        {"Driver"}
                                      </Label>
                                      <div className="input-div">
                                        <Select
                                          options={driverTachnoList}
                                          value={driverTachnoList?.find(
                                            (option) =>
                                              option.value ===
                                              vehicleData.driver
                                          )}
                                          onChange={(selectedOption) => {
                                            if (selectedOption) {
                                              setVehicleData((prevData) => ({
                                                ...prevData,
                                                driver: selectedOption.value,
                                              }));
                                            }
                                          }}
                                          placeholder="Select Driver"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <Label className="col-form-label">
                                        {"Regulation"}
                                      </Label>
                                      <div className="input-div">
                                        <Select
                                          options={RegulationList}
                                          value={RegulationList?.find(
                                            (option) =>
                                              option.value ===
                                              vehicleData.regulation
                                          )}
                                          onChange={(selectedOption) => {
                                            if (selectedOption) {
                                              setVehicleData((prevData) => ({
                                                ...prevData,
                                                regulation:
                                                  selectedOption.value,
                                              }));
                                            }
                                          }}
                                          placeholder="Select Regulation"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                          <div className="row">
                            <div className="col-md-6">
                              <Label>Start Date</Label>

                              <div className="input-group">
                                <input
                                  type="date"
                                  id="vehicleDate"
                                  className="form-control"
                                  value={vehicleDate}
                                  onChange={(e) =>
                                    setVehicleDate(e.target.value)
                                  }
                                  placeholder="Vehicle Date"
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <Label>End Date</Label>

                              <div className="input-group">
                                <input
                                  type="date"
                                  id="vehicleDate"
                                  className="form-control"
                                  value={vehicleDates}
                                  onChange={(e) =>
                                    setVehicleDates(e.target.value)
                                  }
                                  placeholder="Vehicle Date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </FormGroup>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          color="success btn-info"
                          type={`submit`}
                          className={`btn-square `}
                        >{`Submit`}</Button>
                      </div>
                    </Form>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TachnoGraphAnalysis;
