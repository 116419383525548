import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import {
  actionAlarmReports,
  getRoundTripReportList,
} from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import * as XLSX from "xlsx";


const RoundTripList = () => {
  const [alarmsDetails, setAlarmsDetails] = useState("");
  const [alarmsDetailsList, setAlarmsDetailsList] = useState([]);
  const storedData = localStorage.getItem("rounTripReportData");
  const parsedData = JSON.parse(storedData);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const alarms = event.data;
      setAlarmsDetails(alarms);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    // if (alarmsDetails) {
      let data = {
        vehicleIds: parsedData?.vehicleIds,
        startDate: parsedData?.startDate,
        placeA: parsedData?.placeA,
        placeB: parsedData?.placeB,
        ignition: parsedData?.ignition,
        minStopoverTime: parsedData?.minStopoverTime,
      };
      getRoundTripReportList(data)
        .then((response) => {
          setAlarmsDetailsList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    // }
  }, [alarmsDetails]);

  const formatTime = (timeString) => {
    const date = new Date(timeString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  // Helper function to calculate the stopover duration (here we assume you have it or need to calculate)
  const formatStopoverDuration = (start, end) => {
    const duration = new Date(end) - new Date(start); // in milliseconds
    const minutes = Math.floor(duration / 60000); // convert to minutes
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins < 10 ? "0" : ""}${mins}`;
  };


  const exportToExcel = () => {
    if (!alarmsDetailsList || alarmsDetailsList.length === 0) {
      toast.error("No data to export!");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(alarmsDetailsList);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alarms");

    XLSX.writeFile(workbook, "round_trip.xlsx");
  };

  return (
    <div>
      <section className="totalsB">
        <section className="mt-3">
          <div className="tablePageMain mt-2">
            <div className>
              <div className="logoimg">
                <img src={Logo} alt="logoimg" />
              </div>
            </div>
            <div className>
              <div className="Content">
                <div>
                  <h3>Round Trip</h3>
                  <h5 style={{cursor:"pointer"}} onClick={exportToExcel}>Export to Excel</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="tablePage">
            <div className="container-fluid">
              <div className="table-title">
                <h5>Totals : -</h5>
              </div>
              <div className="custom-table mt-4 table-responsive">
                <table className="table table-striped table-hover">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Vehicle</th>
                      <th scope="col">Trip Time</th>
                      <th scope="col">GPS mileage (km)</th>
                      <th scope="col">Stopover</th>
                      <th scope="col">Numbers of trips</th>
                      <th scope="col" />
                      <th scope="col">Trip Time</th>
                      <th scope="col">GPS mileage (km)</th>
                      <th scope="col">Stopover</th>
                      <th scope="col">Numbers of trips</th>
                      <th />
                      <th scope="col">Trip Time</th>
                      <th scope="col">GPS mileage (km)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Ensure that alarmsDetailsList is available before rendering */}
                    {alarmsDetailsList ? (
                      <>
                        {alarmsDetailsList?.map((trip, index) => (
                          <tr key={index}>
                            <th scope="row">41</th>{" "}
                            {/* Replace with dynamic vehicle ID if needed */}
                            <td>{formatTime(trip.start)}</td>
                            <td className="text-end">{trip.tripOdometer}</td>
                            <td className="text-end">
                              {/* Stopover logic (if needed) */}
                              {index > 0
                                ? formatStopoverDuration(
                                    alarmsDetailsList.trips[index - 1].end,
                                    trip.start
                                  )
                                : "N/A"}
                            </td>
                            <td>{index + 1}</td>
                            <td />
                            <td className="text-end">{formatTime(trip.end)}</td>
                            <td className="text-end">{trip.tripOdometer}</td>
                            <td className="text-end">
                              {/* Stopover logic (if needed) */}
                              {index > 0
                                ? formatStopoverDuration(
                                    alarmsDetailsList.trips[index - 1].end,
                                    trip.start
                                  )
                                : "N/A"}
                            </td>
                            <td>{index + 1}</td>
                            <td />
                            <td>{formatTime(trip.end)}</td>
                            <td>{trip.tripOdometer}</td>
                            <td>
                              {formatStopoverDuration(trip.start, trip.end)}
                            </td>{" "}
                            <td>
                            </td>{" "}
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan="13" className="text-center">
                          No information found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default RoundTripList;
