import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import {
  actionAlarmReports,
  actionFuelCostReports,
} from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import * as XLSX from "xlsx";

const CostFuelList = () => {
  const [alarmsDetails, setAlarmsDetails] = useState("");
  const [alarmsDetailsList, setAlarmsDetailsList] = useState([]);
  const storedData = localStorage.getItem("costFuelReportData");
  const parsedData = JSON.parse(storedData);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const alarms = event.data;
      setAlarmsDetails(alarms);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    let data = {
      vehicle_id: parsedData?.vehicle_id,
      reportDate: parsedData?.reportDate,
    };
    actionFuelCostReports(data)
      .then((response) => {
        setAlarmsDetailsList(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  const exportToExcel = () => {
    if (!alarmsDetailsList || alarmsDetailsList.length === 0) {
      toast.error("No data to export!");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(alarmsDetailsList);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alarms");

    XLSX.writeFile(workbook, "fuel_cost_details.xlsx");
  };

  return (
    <div>
      <h1>Cost Fuel Reports</h1>
      {/* {alarmsDetailsList?.length > 0 ? ( */}
      <>
        <div className="tablePage">
          <div className="container-fluid">
            <div className="tablePageMain mt-2">
              <div className>
                <div className="logoimg">
                  <img src={Logo} alt="logoimg" />
                </div>
              </div>
              <div className>
                <div className="Content">
                  <div>
                    <h3>COST AND FUEL</h3>
                    <h5 style={{ cursor: "pointer" }} onClick={exportToExcel}>
                      Export to Excel
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            {alarmsDetailsList?.length > 0 ? (
              <div className="custom-table mt-4">
                <table className="table table-striped table-hover">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Vehicle Name</th>
                      <th scope="col">Fuel Rate GPS</th>
                      <th scope="col">Trip Odometer</th>
                      <th scope="col">Fuel Used GPS</th>
                      <th scope="col">Total Odometer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alarmsDetailsList?.map((alarm) => {
                      return (
                        <tr>
                          <td>{alarm?.vehicle_name}</td>
                          <td>{alarm?.fuel_rate_gps}</td>
                          <td>{alarm?.trip_odometer}</td>
                          <td>{alarm?.fuel_used_gps}</td>
                          <td>{alarm?.total_odometer}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No Cost Fuel details available.</p>
            )}
          </div>
        </div>
      </>
      {/* ) : (
      )} */}
    </div>
  );
};

export default CostFuelList;
